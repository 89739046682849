/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { Struct } from "./google/protobuf/struct";
import {
  DateContainer,
  HttpRequestMethod,
  httpRequestMethodFromJSON,
  httpRequestMethodToJSON,
  Type,
  typeFromJSON,
  typeToJSON,
  UserIdContainer,
} from "./misc";

export const protobufPackage = "";

export enum WebhookRequestError {
  ERROR_UNKNOWN = 0,
  E_DUPLICATE = 1,
  E_REQUIRED_MISSING = 2,
  E_MAPPING_MISSING = 3,
  E_INTERNAL = 4,
  E_PREREQUISITE_ERROR = 5,
  E_EXECUTIVE_MISSING = 6,
  E_VALIDATION_ERROR = 7,
  E_IDENTIFIER_MISSING = 8,
  UNRECOGNIZED = -1,
}

export function webhookRequestErrorFromJSON(object: any): WebhookRequestError {
  switch (object) {
    case 0:
    case "ERROR_UNKNOWN":
      return WebhookRequestError.ERROR_UNKNOWN;
    case 1:
    case "E_DUPLICATE":
      return WebhookRequestError.E_DUPLICATE;
    case 2:
    case "E_REQUIRED_MISSING":
      return WebhookRequestError.E_REQUIRED_MISSING;
    case 3:
    case "E_MAPPING_MISSING":
      return WebhookRequestError.E_MAPPING_MISSING;
    case 4:
    case "E_INTERNAL":
      return WebhookRequestError.E_INTERNAL;
    case 5:
    case "E_PREREQUISITE_ERROR":
      return WebhookRequestError.E_PREREQUISITE_ERROR;
    case 6:
    case "E_EXECUTIVE_MISSING":
      return WebhookRequestError.E_EXECUTIVE_MISSING;
    case 7:
    case "E_VALIDATION_ERROR":
      return WebhookRequestError.E_VALIDATION_ERROR;
    case 8:
    case "E_IDENTIFIER_MISSING":
      return WebhookRequestError.E_IDENTIFIER_MISSING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WebhookRequestError.UNRECOGNIZED;
  }
}

export function webhookRequestErrorToJSON(object: WebhookRequestError): string {
  switch (object) {
    case WebhookRequestError.ERROR_UNKNOWN:
      return "ERROR_UNKNOWN";
    case WebhookRequestError.E_DUPLICATE:
      return "E_DUPLICATE";
    case WebhookRequestError.E_REQUIRED_MISSING:
      return "E_REQUIRED_MISSING";
    case WebhookRequestError.E_MAPPING_MISSING:
      return "E_MAPPING_MISSING";
    case WebhookRequestError.E_INTERNAL:
      return "E_INTERNAL";
    case WebhookRequestError.E_PREREQUISITE_ERROR:
      return "E_PREREQUISITE_ERROR";
    case WebhookRequestError.E_EXECUTIVE_MISSING:
      return "E_EXECUTIVE_MISSING";
    case WebhookRequestError.E_VALIDATION_ERROR:
      return "E_VALIDATION_ERROR";
    case WebhookRequestError.E_IDENTIFIER_MISSING:
      return "E_IDENTIFIER_MISSING";
    case WebhookRequestError.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PushEvent {
  PE_UNKNOWN = 0,
  PE_DIALER_CALL_BRIDGED_TO_AGENT = 1000,
  PE_DIALER_CALL_AGENT_LEFT = 1001,
  PE_DIALER_CALL_ENDED = 1002,
  PE_DIALER_CALL_UPDATED = 1050,
  PE_DIALER_CALL_UPDATED_DISPO = 1051,
  PE_DIALER_LEAD_UPDATED = 1100,
  UNRECOGNIZED = -1,
}

export function pushEventFromJSON(object: any): PushEvent {
  switch (object) {
    case 0:
    case "PE_UNKNOWN":
      return PushEvent.PE_UNKNOWN;
    case 1000:
    case "PE_DIALER_CALL_BRIDGED_TO_AGENT":
      return PushEvent.PE_DIALER_CALL_BRIDGED_TO_AGENT;
    case 1001:
    case "PE_DIALER_CALL_AGENT_LEFT":
      return PushEvent.PE_DIALER_CALL_AGENT_LEFT;
    case 1002:
    case "PE_DIALER_CALL_ENDED":
      return PushEvent.PE_DIALER_CALL_ENDED;
    case 1050:
    case "PE_DIALER_CALL_UPDATED":
      return PushEvent.PE_DIALER_CALL_UPDATED;
    case 1051:
    case "PE_DIALER_CALL_UPDATED_DISPO":
      return PushEvent.PE_DIALER_CALL_UPDATED_DISPO;
    case 1100:
    case "PE_DIALER_LEAD_UPDATED":
      return PushEvent.PE_DIALER_LEAD_UPDATED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PushEvent.UNRECOGNIZED;
  }
}

export function pushEventToJSON(object: PushEvent): string {
  switch (object) {
    case PushEvent.PE_UNKNOWN:
      return "PE_UNKNOWN";
    case PushEvent.PE_DIALER_CALL_BRIDGED_TO_AGENT:
      return "PE_DIALER_CALL_BRIDGED_TO_AGENT";
    case PushEvent.PE_DIALER_CALL_AGENT_LEFT:
      return "PE_DIALER_CALL_AGENT_LEFT";
    case PushEvent.PE_DIALER_CALL_ENDED:
      return "PE_DIALER_CALL_ENDED";
    case PushEvent.PE_DIALER_CALL_UPDATED:
      return "PE_DIALER_CALL_UPDATED";
    case PushEvent.PE_DIALER_CALL_UPDATED_DISPO:
      return "PE_DIALER_CALL_UPDATED_DISPO";
    case PushEvent.PE_DIALER_LEAD_UPDATED:
      return "PE_DIALER_LEAD_UPDATED";
    case PushEvent.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ImporterChannelGroup {
  id?: string | undefined;
  name?: string | undefined;
  currentVersion?: number | undefined;
  type?: Type | undefined;
  source?: ImporterChannel_Source | undefined;
  mapping?: { [key: string]: any } | undefined;
  prerequisiteMapping?: { [key: string]: any } | undefined;
}

export interface ImporterChannel {
  id?: string | undefined;
  status?: ImporterChannel_Status | undefined;
  currentVersion?:
    | number
    | undefined;
  /** use javascript object */
  mapping?: { [key: string]: any } | undefined;
  groupId?: string | undefined;
  groupName?: string | undefined;
  type?: Type | undefined;
  public?: boolean | undefined;
  userId?: string | undefined;
  name?: string | undefined;
  webhookKey?: string | undefined;
  source?: ImporterChannel_Source | undefined;
  mappingMissing?: { [key: string]: any } | undefined;
  createdAt?: DateContainer | undefined;
  file?: ImporterChannel_File | undefined;
}

export enum ImporterChannel_Source {
  SOURCE_UNKNOWN = 0,
  S_WEBHOOK = 1,
  S_FILE = 2,
  S_PREREQUISITE = 3,
  UNRECOGNIZED = -1,
}

export function importerChannel_SourceFromJSON(object: any): ImporterChannel_Source {
  switch (object) {
    case 0:
    case "SOURCE_UNKNOWN":
      return ImporterChannel_Source.SOURCE_UNKNOWN;
    case 1:
    case "S_WEBHOOK":
      return ImporterChannel_Source.S_WEBHOOK;
    case 2:
    case "S_FILE":
      return ImporterChannel_Source.S_FILE;
    case 3:
    case "S_PREREQUISITE":
      return ImporterChannel_Source.S_PREREQUISITE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ImporterChannel_Source.UNRECOGNIZED;
  }
}

export function importerChannel_SourceToJSON(object: ImporterChannel_Source): string {
  switch (object) {
    case ImporterChannel_Source.SOURCE_UNKNOWN:
      return "SOURCE_UNKNOWN";
    case ImporterChannel_Source.S_WEBHOOK:
      return "S_WEBHOOK";
    case ImporterChannel_Source.S_FILE:
      return "S_FILE";
    case ImporterChannel_Source.S_PREREQUISITE:
      return "S_PREREQUISITE";
    case ImporterChannel_Source.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ImporterChannel_Status {
  STATUS_UNKNOWN = 0,
  S_DRAFT = 1,
  S_ACTIVE = 2,
  S_INACTIVE = 3,
  UNRECOGNIZED = -1,
}

export function importerChannel_StatusFromJSON(object: any): ImporterChannel_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return ImporterChannel_Status.STATUS_UNKNOWN;
    case 1:
    case "S_DRAFT":
      return ImporterChannel_Status.S_DRAFT;
    case 2:
    case "S_ACTIVE":
      return ImporterChannel_Status.S_ACTIVE;
    case 3:
    case "S_INACTIVE":
      return ImporterChannel_Status.S_INACTIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ImporterChannel_Status.UNRECOGNIZED;
  }
}

export function importerChannel_StatusToJSON(object: ImporterChannel_Status): string {
  switch (object) {
    case ImporterChannel_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case ImporterChannel_Status.S_DRAFT:
      return "S_DRAFT";
    case ImporterChannel_Status.S_ACTIVE:
      return "S_ACTIVE";
    case ImporterChannel_Status.S_INACTIVE:
      return "S_INACTIVE";
    case ImporterChannel_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ImporterChannel_Mapping {
}

export interface ImporterChannel_File {
  rawPath?: string | undefined;
  chunksCompleted?: number | undefined;
  chunksTotal?: number | undefined;
  createdAt?: number | undefined;
  importStatus?: string | undefined;
  leadsTotal?: number | undefined;
  statusCount?: ImporterChannel_File_StatusCount | undefined;
  fileName?: string | undefined;
  sheetName?: string | undefined;
  fileSize?: number | undefined;
  lastModified?: number | undefined;
  fileType?: string | undefined;
}

export interface ImporterChannel_File_StatusCount {
  skipped?: number | undefined;
  success?: number | undefined;
  error?: number | undefined;
}

export interface ImporterImport {
  id?: string | undefined;
  raw?: { [key: string]: any } | undefined;
  status?: ImporterImport_Status | undefined;
  tryCount?: number | undefined;
  userId?: string | undefined;
  importerChannelId?: string | undefined;
  firstTryStarted?: number | undefined;
  lastTryFinished?: number | undefined;
  metadata?: { [key: string]: any } | undefined;
  error?: ImporterImport_Error | undefined;
  errorMetadata?: { [key: string]: any } | undefined;
  destinationId?: string | undefined;
  destinationPath?: string | undefined;
  lastImportVersion?: number | undefined;
}

export enum ImporterImport_Status {
  IMPORT_STATUS_UNKNOWN = 0,
  IS_PENDING = 1,
  IS_SUCCESS = 10,
  IS_ERROR = 20,
  UNRECOGNIZED = -1,
}

export function importerImport_StatusFromJSON(object: any): ImporterImport_Status {
  switch (object) {
    case 0:
    case "IMPORT_STATUS_UNKNOWN":
      return ImporterImport_Status.IMPORT_STATUS_UNKNOWN;
    case 1:
    case "IS_PENDING":
      return ImporterImport_Status.IS_PENDING;
    case 10:
    case "IS_SUCCESS":
      return ImporterImport_Status.IS_SUCCESS;
    case 20:
    case "IS_ERROR":
      return ImporterImport_Status.IS_ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ImporterImport_Status.UNRECOGNIZED;
  }
}

export function importerImport_StatusToJSON(object: ImporterImport_Status): string {
  switch (object) {
    case ImporterImport_Status.IMPORT_STATUS_UNKNOWN:
      return "IMPORT_STATUS_UNKNOWN";
    case ImporterImport_Status.IS_PENDING:
      return "IS_PENDING";
    case ImporterImport_Status.IS_SUCCESS:
      return "IS_SUCCESS";
    case ImporterImport_Status.IS_ERROR:
      return "IS_ERROR";
    case ImporterImport_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ImporterImport_Error {
  ERROR_UNKNOWN = 0,
  E_DUPLICATE = 1,
  E_REQUIRED_MISSING = 2,
  E_MAPPING_MISSING = 3,
  E_INTERNAL = 4,
  E_PREREQUISITE_ERROR = 5,
  E_EXECUTIVE_MISSING = 6,
  E_VALIDATION_ERROR = 7,
  E_IDENTIFIER_MISSING = 8,
  UNRECOGNIZED = -1,
}

export function importerImport_ErrorFromJSON(object: any): ImporterImport_Error {
  switch (object) {
    case 0:
    case "ERROR_UNKNOWN":
      return ImporterImport_Error.ERROR_UNKNOWN;
    case 1:
    case "E_DUPLICATE":
      return ImporterImport_Error.E_DUPLICATE;
    case 2:
    case "E_REQUIRED_MISSING":
      return ImporterImport_Error.E_REQUIRED_MISSING;
    case 3:
    case "E_MAPPING_MISSING":
      return ImporterImport_Error.E_MAPPING_MISSING;
    case 4:
    case "E_INTERNAL":
      return ImporterImport_Error.E_INTERNAL;
    case 5:
    case "E_PREREQUISITE_ERROR":
      return ImporterImport_Error.E_PREREQUISITE_ERROR;
    case 6:
    case "E_EXECUTIVE_MISSING":
      return ImporterImport_Error.E_EXECUTIVE_MISSING;
    case 7:
    case "E_VALIDATION_ERROR":
      return ImporterImport_Error.E_VALIDATION_ERROR;
    case 8:
    case "E_IDENTIFIER_MISSING":
      return ImporterImport_Error.E_IDENTIFIER_MISSING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ImporterImport_Error.UNRECOGNIZED;
  }
}

export function importerImport_ErrorToJSON(object: ImporterImport_Error): string {
  switch (object) {
    case ImporterImport_Error.ERROR_UNKNOWN:
      return "ERROR_UNKNOWN";
    case ImporterImport_Error.E_DUPLICATE:
      return "E_DUPLICATE";
    case ImporterImport_Error.E_REQUIRED_MISSING:
      return "E_REQUIRED_MISSING";
    case ImporterImport_Error.E_MAPPING_MISSING:
      return "E_MAPPING_MISSING";
    case ImporterImport_Error.E_INTERNAL:
      return "E_INTERNAL";
    case ImporterImport_Error.E_PREREQUISITE_ERROR:
      return "E_PREREQUISITE_ERROR";
    case ImporterImport_Error.E_EXECUTIVE_MISSING:
      return "E_EXECUTIVE_MISSING";
    case ImporterImport_Error.E_VALIDATION_ERROR:
      return "E_VALIDATION_ERROR";
    case ImporterImport_Error.E_IDENTIFIER_MISSING:
      return "E_IDENTIFIER_MISSING";
    case ImporterImport_Error.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WebhookChannel {
  id?: string | undefined;
  status?: WebhookChannel_Status | undefined;
  currentVersion?:
    | number
    | undefined;
  /** use javascript object */
  mapping?: { [key: string]: any } | undefined;
  groupId?: string | undefined;
  groupName?: string | undefined;
  type?: Type | undefined;
  public?: boolean | undefined;
  userId?: string | undefined;
  name?: string | undefined;
  webhookKey?: string | undefined;
  source?: WebhookChannel_Source | undefined;
  mappingMissing?: { [key: string]: any } | undefined;
  createdAt?: DateContainer | undefined;
  event?: WebhookChannel_EventContainer | undefined;
  endpointType?: HttpRequestMethod | undefined;
  url?: string | undefined;
  urlBackup?: string | undefined;
  mappingTemplate?: string | undefined;
}

export enum WebhookChannel_Source {
  SOURCE_UNKNOWN = 0,
  S_WEBHOOK = 1,
  S_FILE = 2,
  S_PREREQUISITE = 3,
  UNRECOGNIZED = -1,
}

export function webhookChannel_SourceFromJSON(object: any): WebhookChannel_Source {
  switch (object) {
    case 0:
    case "SOURCE_UNKNOWN":
      return WebhookChannel_Source.SOURCE_UNKNOWN;
    case 1:
    case "S_WEBHOOK":
      return WebhookChannel_Source.S_WEBHOOK;
    case 2:
    case "S_FILE":
      return WebhookChannel_Source.S_FILE;
    case 3:
    case "S_PREREQUISITE":
      return WebhookChannel_Source.S_PREREQUISITE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WebhookChannel_Source.UNRECOGNIZED;
  }
}

export function webhookChannel_SourceToJSON(object: WebhookChannel_Source): string {
  switch (object) {
    case WebhookChannel_Source.SOURCE_UNKNOWN:
      return "SOURCE_UNKNOWN";
    case WebhookChannel_Source.S_WEBHOOK:
      return "S_WEBHOOK";
    case WebhookChannel_Source.S_FILE:
      return "S_FILE";
    case WebhookChannel_Source.S_PREREQUISITE:
      return "S_PREREQUISITE";
    case WebhookChannel_Source.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum WebhookChannel_Status {
  STATUS_UNKNOWN = 0,
  S_DRAFT = 1,
  S_ACTIVE = 2,
  S_INACTIVE = 3,
  UNRECOGNIZED = -1,
}

export function webhookChannel_StatusFromJSON(object: any): WebhookChannel_Status {
  switch (object) {
    case 0:
    case "STATUS_UNKNOWN":
      return WebhookChannel_Status.STATUS_UNKNOWN;
    case 1:
    case "S_DRAFT":
      return WebhookChannel_Status.S_DRAFT;
    case 2:
    case "S_ACTIVE":
      return WebhookChannel_Status.S_ACTIVE;
    case 3:
    case "S_INACTIVE":
      return WebhookChannel_Status.S_INACTIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WebhookChannel_Status.UNRECOGNIZED;
  }
}

export function webhookChannel_StatusToJSON(object: WebhookChannel_Status): string {
  switch (object) {
    case WebhookChannel_Status.STATUS_UNKNOWN:
      return "STATUS_UNKNOWN";
    case WebhookChannel_Status.S_DRAFT:
      return "S_DRAFT";
    case WebhookChannel_Status.S_ACTIVE:
      return "S_ACTIVE";
    case WebhookChannel_Status.S_INACTIVE:
      return "S_INACTIVE";
    case WebhookChannel_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WebhookChannel_Mapping {
}

export interface WebhookChannel_EventContainer {
  ids: PushEvent[];
}

export interface WebhookChannel_File {
  rawPath?: string | undefined;
  chunksCompleted?: number | undefined;
  chunksTotal?: number | undefined;
  createdAt?: number | undefined;
  importStatus?: string | undefined;
  leadsTotal?: number | undefined;
  statusCount?: WebhookChannel_File_StatusCount | undefined;
  fileName?: string | undefined;
  sheetName?: string | undefined;
  fileSize?: number | undefined;
  lastModified?: number | undefined;
  fileType?: string | undefined;
}

export interface WebhookChannel_File_StatusCount {
  skipped?: number | undefined;
  success?: number | undefined;
  error?: number | undefined;
}

export interface WebhookRequest {
  id?: string | undefined;
  raw?: { [key: string]: any } | undefined;
  status?: ImporterImport_Status | undefined;
  tryCount?: number | undefined;
  userId?: string | undefined;
  importerChannelId?: string | undefined;
  firstTryStarted?: number | undefined;
  lastTryFinished?: number | undefined;
  metadata?: { [key: string]: any } | undefined;
  error?: WebhookRequestError | undefined;
  errorMetadata?: { [key: string]: any } | undefined;
  lastImportVersion?:
    | number
    | undefined;
  /** different to ImporterImport */
  tries: WebhookRequestTry[];
  destinationUrl?: string | undefined;
  resultStatusCode?: string | undefined;
}

export interface WebhookRequestTry {
  status?: string | undefined;
  headers?: { [key: string]: any } | undefined;
  body?: { [key: string]: any } | undefined;
  tryFinished?: number | undefined;
  name?: string | undefined;
}

export interface WebhookEventBody {
  user?: UserIdContainer | undefined;
  type?: Type | undefined;
  event?: PushEvent | undefined;
  data?: { [key: string]: any } | undefined;
  timestamp?: number | undefined;
}

function createBaseImporterChannelGroup(): ImporterChannelGroup {
  return {
    id: undefined,
    name: undefined,
    currentVersion: undefined,
    type: undefined,
    source: undefined,
    mapping: undefined,
    prerequisiteMapping: undefined,
  };
}

export const ImporterChannelGroup = {
  encode(message: ImporterChannelGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.currentVersion !== undefined) {
      writer.uint32(24).int32(message.currentVersion);
    }
    if (message.type !== undefined) {
      writer.uint32(32).int32(message.type);
    }
    if (message.source !== undefined) {
      writer.uint32(40).int32(message.source);
    }
    if (message.mapping !== undefined) {
      Struct.encode(Struct.wrap(message.mapping), writer.uint32(50).fork()).ldelim();
    }
    if (message.prerequisiteMapping !== undefined) {
      Struct.encode(Struct.wrap(message.prerequisiteMapping), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImporterChannelGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImporterChannelGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.currentVersion = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.mapping = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.prerequisiteMapping = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImporterChannelGroup {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      currentVersion: isSet(object.currentVersion) ? Number(object.currentVersion) : undefined,
      type: isSet(object.type) ? typeFromJSON(object.type) : undefined,
      source: isSet(object.source) ? importerChannel_SourceFromJSON(object.source) : undefined,
      mapping: isObject(object.mapping) ? object.mapping : undefined,
      prerequisiteMapping: isObject(object.prerequisiteMapping) ? object.prerequisiteMapping : undefined,
    };
  },

  toJSON(message: ImporterChannelGroup): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.currentVersion !== undefined && (obj.currentVersion = Math.round(message.currentVersion));
    message.type !== undefined && (obj.type = message.type !== undefined ? typeToJSON(message.type) : undefined);
    message.source !== undefined &&
      (obj.source = message.source !== undefined ? importerChannel_SourceToJSON(message.source) : undefined);
    message.mapping !== undefined && (obj.mapping = message.mapping);
    message.prerequisiteMapping !== undefined && (obj.prerequisiteMapping = message.prerequisiteMapping);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImporterChannelGroup>, I>>(base?: I): ImporterChannelGroup {
    return ImporterChannelGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImporterChannelGroup>, I>>(object: I): ImporterChannelGroup {
    const message = createBaseImporterChannelGroup();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.currentVersion = object.currentVersion ?? undefined;
    message.type = object.type ?? undefined;
    message.source = object.source ?? undefined;
    message.mapping = object.mapping ?? undefined;
    message.prerequisiteMapping = object.prerequisiteMapping ?? undefined;
    return message;
  },
};

function createBaseImporterChannel(): ImporterChannel {
  return {
    id: undefined,
    status: undefined,
    currentVersion: undefined,
    mapping: undefined,
    groupId: undefined,
    groupName: undefined,
    type: undefined,
    public: undefined,
    userId: undefined,
    name: undefined,
    webhookKey: undefined,
    source: undefined,
    mappingMissing: undefined,
    createdAt: undefined,
    file: undefined,
  };
}

export const ImporterChannel = {
  encode(message: ImporterChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.status !== undefined) {
      writer.uint32(16).int32(message.status);
    }
    if (message.currentVersion !== undefined) {
      writer.uint32(24).int32(message.currentVersion);
    }
    if (message.mapping !== undefined) {
      Struct.encode(Struct.wrap(message.mapping), writer.uint32(34).fork()).ldelim();
    }
    if (message.groupId !== undefined) {
      writer.uint32(42).string(message.groupId);
    }
    if (message.groupName !== undefined) {
      writer.uint32(50).string(message.groupName);
    }
    if (message.type !== undefined) {
      writer.uint32(56).int32(message.type);
    }
    if (message.public !== undefined) {
      writer.uint32(64).bool(message.public);
    }
    if (message.userId !== undefined) {
      writer.uint32(74).string(message.userId);
    }
    if (message.name !== undefined) {
      writer.uint32(82).string(message.name);
    }
    if (message.webhookKey !== undefined) {
      writer.uint32(90).string(message.webhookKey);
    }
    if (message.source !== undefined) {
      writer.uint32(96).int32(message.source);
    }
    if (message.mappingMissing !== undefined) {
      Struct.encode(Struct.wrap(message.mappingMissing), writer.uint32(106).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      DateContainer.encode(message.createdAt, writer.uint32(114).fork()).ldelim();
    }
    if (message.file !== undefined) {
      ImporterChannel_File.encode(message.file, writer.uint32(794).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImporterChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImporterChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.currentVersion = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mapping = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.groupName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.public = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.name = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.webhookKey = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.mappingMissing = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.createdAt = DateContainer.decode(reader, reader.uint32());
          continue;
        case 99:
          if (tag !== 794) {
            break;
          }

          message.file = ImporterChannel_File.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImporterChannel {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      status: isSet(object.status) ? importerChannel_StatusFromJSON(object.status) : undefined,
      currentVersion: isSet(object.currentVersion) ? Number(object.currentVersion) : undefined,
      mapping: isObject(object.mapping) ? object.mapping : undefined,
      groupId: isSet(object.groupId) ? String(object.groupId) : undefined,
      groupName: isSet(object.groupName) ? String(object.groupName) : undefined,
      type: isSet(object.type) ? typeFromJSON(object.type) : undefined,
      public: isSet(object.public) ? Boolean(object.public) : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      webhookKey: isSet(object.webhookKey) ? String(object.webhookKey) : undefined,
      source: isSet(object.source) ? importerChannel_SourceFromJSON(object.source) : undefined,
      mappingMissing: isObject(object.mappingMissing) ? object.mappingMissing : undefined,
      createdAt: isSet(object.createdAt) ? DateContainer.fromJSON(object.createdAt) : undefined,
      file: isSet(object.file) ? ImporterChannel_File.fromJSON(object.file) : undefined,
    };
  },

  toJSON(message: ImporterChannel): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? importerChannel_StatusToJSON(message.status) : undefined);
    message.currentVersion !== undefined && (obj.currentVersion = Math.round(message.currentVersion));
    message.mapping !== undefined && (obj.mapping = message.mapping);
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.groupName !== undefined && (obj.groupName = message.groupName);
    message.type !== undefined && (obj.type = message.type !== undefined ? typeToJSON(message.type) : undefined);
    message.public !== undefined && (obj.public = message.public);
    message.userId !== undefined && (obj.userId = message.userId);
    message.name !== undefined && (obj.name = message.name);
    message.webhookKey !== undefined && (obj.webhookKey = message.webhookKey);
    message.source !== undefined &&
      (obj.source = message.source !== undefined ? importerChannel_SourceToJSON(message.source) : undefined);
    message.mappingMissing !== undefined && (obj.mappingMissing = message.mappingMissing);
    message.createdAt !== undefined &&
      (obj.createdAt = message.createdAt ? DateContainer.toJSON(message.createdAt) : undefined);
    message.file !== undefined && (obj.file = message.file ? ImporterChannel_File.toJSON(message.file) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImporterChannel>, I>>(base?: I): ImporterChannel {
    return ImporterChannel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImporterChannel>, I>>(object: I): ImporterChannel {
    const message = createBaseImporterChannel();
    message.id = object.id ?? undefined;
    message.status = object.status ?? undefined;
    message.currentVersion = object.currentVersion ?? undefined;
    message.mapping = object.mapping ?? undefined;
    message.groupId = object.groupId ?? undefined;
    message.groupName = object.groupName ?? undefined;
    message.type = object.type ?? undefined;
    message.public = object.public ?? undefined;
    message.userId = object.userId ?? undefined;
    message.name = object.name ?? undefined;
    message.webhookKey = object.webhookKey ?? undefined;
    message.source = object.source ?? undefined;
    message.mappingMissing = object.mappingMissing ?? undefined;
    message.createdAt = (object.createdAt !== undefined && object.createdAt !== null)
      ? DateContainer.fromPartial(object.createdAt)
      : undefined;
    message.file = (object.file !== undefined && object.file !== null)
      ? ImporterChannel_File.fromPartial(object.file)
      : undefined;
    return message;
  },
};

function createBaseImporterChannel_Mapping(): ImporterChannel_Mapping {
  return {};
}

export const ImporterChannel_Mapping = {
  encode(_: ImporterChannel_Mapping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImporterChannel_Mapping {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImporterChannel_Mapping();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ImporterChannel_Mapping {
    return {};
  },

  toJSON(_: ImporterChannel_Mapping): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ImporterChannel_Mapping>, I>>(base?: I): ImporterChannel_Mapping {
    return ImporterChannel_Mapping.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImporterChannel_Mapping>, I>>(_: I): ImporterChannel_Mapping {
    const message = createBaseImporterChannel_Mapping();
    return message;
  },
};

function createBaseImporterChannel_File(): ImporterChannel_File {
  return {
    rawPath: undefined,
    chunksCompleted: undefined,
    chunksTotal: undefined,
    createdAt: undefined,
    importStatus: undefined,
    leadsTotal: undefined,
    statusCount: undefined,
    fileName: undefined,
    sheetName: undefined,
    fileSize: undefined,
    lastModified: undefined,
    fileType: undefined,
  };
}

export const ImporterChannel_File = {
  encode(message: ImporterChannel_File, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rawPath !== undefined) {
      writer.uint32(10).string(message.rawPath);
    }
    if (message.chunksCompleted !== undefined) {
      writer.uint32(16).int32(message.chunksCompleted);
    }
    if (message.chunksTotal !== undefined) {
      writer.uint32(24).int32(message.chunksTotal);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(32).int64(message.createdAt);
    }
    if (message.importStatus !== undefined) {
      writer.uint32(42).string(message.importStatus);
    }
    if (message.leadsTotal !== undefined) {
      writer.uint32(48).int32(message.leadsTotal);
    }
    if (message.statusCount !== undefined) {
      ImporterChannel_File_StatusCount.encode(message.statusCount, writer.uint32(58).fork()).ldelim();
    }
    if (message.fileName !== undefined) {
      writer.uint32(66).string(message.fileName);
    }
    if (message.sheetName !== undefined) {
      writer.uint32(74).string(message.sheetName);
    }
    if (message.fileSize !== undefined) {
      writer.uint32(80).int64(message.fileSize);
    }
    if (message.lastModified !== undefined) {
      writer.uint32(88).int64(message.lastModified);
    }
    if (message.fileType !== undefined) {
      writer.uint32(98).string(message.fileType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImporterChannel_File {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImporterChannel_File();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rawPath = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.chunksCompleted = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.chunksTotal = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.importStatus = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.leadsTotal = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.statusCount = ImporterChannel_File_StatusCount.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.fileName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.sheetName = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.fileSize = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.lastModified = longToNumber(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.fileType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImporterChannel_File {
    return {
      rawPath: isSet(object.rawPath) ? String(object.rawPath) : undefined,
      chunksCompleted: isSet(object.chunksCompleted) ? Number(object.chunksCompleted) : undefined,
      chunksTotal: isSet(object.chunksTotal) ? Number(object.chunksTotal) : undefined,
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : undefined,
      importStatus: isSet(object.importStatus) ? String(object.importStatus) : undefined,
      leadsTotal: isSet(object.leadsTotal) ? Number(object.leadsTotal) : undefined,
      statusCount: isSet(object.statusCount)
        ? ImporterChannel_File_StatusCount.fromJSON(object.statusCount)
        : undefined,
      fileName: isSet(object.fileName) ? String(object.fileName) : undefined,
      sheetName: isSet(object.sheetName) ? String(object.sheetName) : undefined,
      fileSize: isSet(object.fileSize) ? Number(object.fileSize) : undefined,
      lastModified: isSet(object.lastModified) ? Number(object.lastModified) : undefined,
      fileType: isSet(object.fileType) ? String(object.fileType) : undefined,
    };
  },

  toJSON(message: ImporterChannel_File): unknown {
    const obj: any = {};
    message.rawPath !== undefined && (obj.rawPath = message.rawPath);
    message.chunksCompleted !== undefined && (obj.chunksCompleted = Math.round(message.chunksCompleted));
    message.chunksTotal !== undefined && (obj.chunksTotal = Math.round(message.chunksTotal));
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    message.importStatus !== undefined && (obj.importStatus = message.importStatus);
    message.leadsTotal !== undefined && (obj.leadsTotal = Math.round(message.leadsTotal));
    message.statusCount !== undefined &&
      (obj.statusCount = message.statusCount
        ? ImporterChannel_File_StatusCount.toJSON(message.statusCount)
        : undefined);
    message.fileName !== undefined && (obj.fileName = message.fileName);
    message.sheetName !== undefined && (obj.sheetName = message.sheetName);
    message.fileSize !== undefined && (obj.fileSize = Math.round(message.fileSize));
    message.lastModified !== undefined && (obj.lastModified = Math.round(message.lastModified));
    message.fileType !== undefined && (obj.fileType = message.fileType);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImporterChannel_File>, I>>(base?: I): ImporterChannel_File {
    return ImporterChannel_File.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImporterChannel_File>, I>>(object: I): ImporterChannel_File {
    const message = createBaseImporterChannel_File();
    message.rawPath = object.rawPath ?? undefined;
    message.chunksCompleted = object.chunksCompleted ?? undefined;
    message.chunksTotal = object.chunksTotal ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.importStatus = object.importStatus ?? undefined;
    message.leadsTotal = object.leadsTotal ?? undefined;
    message.statusCount = (object.statusCount !== undefined && object.statusCount !== null)
      ? ImporterChannel_File_StatusCount.fromPartial(object.statusCount)
      : undefined;
    message.fileName = object.fileName ?? undefined;
    message.sheetName = object.sheetName ?? undefined;
    message.fileSize = object.fileSize ?? undefined;
    message.lastModified = object.lastModified ?? undefined;
    message.fileType = object.fileType ?? undefined;
    return message;
  },
};

function createBaseImporterChannel_File_StatusCount(): ImporterChannel_File_StatusCount {
  return { skipped: undefined, success: undefined, error: undefined };
}

export const ImporterChannel_File_StatusCount = {
  encode(message: ImporterChannel_File_StatusCount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.skipped !== undefined) {
      writer.uint32(8).int32(message.skipped);
    }
    if (message.success !== undefined) {
      writer.uint32(16).int32(message.success);
    }
    if (message.error !== undefined) {
      writer.uint32(24).int32(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImporterChannel_File_StatusCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImporterChannel_File_StatusCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.skipped = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.success = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.error = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImporterChannel_File_StatusCount {
    return {
      skipped: isSet(object.skipped) ? Number(object.skipped) : undefined,
      success: isSet(object.success) ? Number(object.success) : undefined,
      error: isSet(object.error) ? Number(object.error) : undefined,
    };
  },

  toJSON(message: ImporterChannel_File_StatusCount): unknown {
    const obj: any = {};
    message.skipped !== undefined && (obj.skipped = Math.round(message.skipped));
    message.success !== undefined && (obj.success = Math.round(message.success));
    message.error !== undefined && (obj.error = Math.round(message.error));
    return obj;
  },

  create<I extends Exact<DeepPartial<ImporterChannel_File_StatusCount>, I>>(
    base?: I,
  ): ImporterChannel_File_StatusCount {
    return ImporterChannel_File_StatusCount.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImporterChannel_File_StatusCount>, I>>(
    object: I,
  ): ImporterChannel_File_StatusCount {
    const message = createBaseImporterChannel_File_StatusCount();
    message.skipped = object.skipped ?? undefined;
    message.success = object.success ?? undefined;
    message.error = object.error ?? undefined;
    return message;
  },
};

function createBaseImporterImport(): ImporterImport {
  return {
    id: undefined,
    raw: undefined,
    status: undefined,
    tryCount: undefined,
    userId: undefined,
    importerChannelId: undefined,
    firstTryStarted: undefined,
    lastTryFinished: undefined,
    metadata: undefined,
    error: undefined,
    errorMetadata: undefined,
    destinationId: undefined,
    destinationPath: undefined,
    lastImportVersion: undefined,
  };
}

export const ImporterImport = {
  encode(message: ImporterImport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.raw !== undefined) {
      Struct.encode(Struct.wrap(message.raw), writer.uint32(18).fork()).ldelim();
    }
    if (message.status !== undefined) {
      writer.uint32(24).int32(message.status);
    }
    if (message.tryCount !== undefined) {
      writer.uint32(32).int32(message.tryCount);
    }
    if (message.userId !== undefined) {
      writer.uint32(42).string(message.userId);
    }
    if (message.importerChannelId !== undefined) {
      writer.uint32(50).string(message.importerChannelId);
    }
    if (message.firstTryStarted !== undefined) {
      writer.uint32(56).int64(message.firstTryStarted);
    }
    if (message.lastTryFinished !== undefined) {
      writer.uint32(64).int64(message.lastTryFinished);
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(74).fork()).ldelim();
    }
    if (message.error !== undefined) {
      writer.uint32(80).int32(message.error);
    }
    if (message.errorMetadata !== undefined) {
      Struct.encode(Struct.wrap(message.errorMetadata), writer.uint32(90).fork()).ldelim();
    }
    if (message.destinationId !== undefined) {
      writer.uint32(98).string(message.destinationId);
    }
    if (message.destinationPath !== undefined) {
      writer.uint32(106).string(message.destinationPath);
    }
    if (message.lastImportVersion !== undefined) {
      writer.uint32(112).int32(message.lastImportVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImporterImport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImporterImport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.raw = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tryCount = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.importerChannelId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.firstTryStarted = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.lastTryFinished = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.error = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.errorMetadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.destinationId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.destinationPath = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.lastImportVersion = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImporterImport {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      raw: isObject(object.raw) ? object.raw : undefined,
      status: isSet(object.status) ? importerImport_StatusFromJSON(object.status) : undefined,
      tryCount: isSet(object.tryCount) ? Number(object.tryCount) : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      importerChannelId: isSet(object.importerChannelId) ? String(object.importerChannelId) : undefined,
      firstTryStarted: isSet(object.firstTryStarted) ? Number(object.firstTryStarted) : undefined,
      lastTryFinished: isSet(object.lastTryFinished) ? Number(object.lastTryFinished) : undefined,
      metadata: isObject(object.metadata) ? object.metadata : undefined,
      error: isSet(object.error) ? importerImport_ErrorFromJSON(object.error) : undefined,
      errorMetadata: isObject(object.errorMetadata) ? object.errorMetadata : undefined,
      destinationId: isSet(object.destinationId) ? String(object.destinationId) : undefined,
      destinationPath: isSet(object.destinationPath) ? String(object.destinationPath) : undefined,
      lastImportVersion: isSet(object.lastImportVersion) ? Number(object.lastImportVersion) : undefined,
    };
  },

  toJSON(message: ImporterImport): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.raw !== undefined && (obj.raw = message.raw);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? importerImport_StatusToJSON(message.status) : undefined);
    message.tryCount !== undefined && (obj.tryCount = Math.round(message.tryCount));
    message.userId !== undefined && (obj.userId = message.userId);
    message.importerChannelId !== undefined && (obj.importerChannelId = message.importerChannelId);
    message.firstTryStarted !== undefined && (obj.firstTryStarted = Math.round(message.firstTryStarted));
    message.lastTryFinished !== undefined && (obj.lastTryFinished = Math.round(message.lastTryFinished));
    message.metadata !== undefined && (obj.metadata = message.metadata);
    message.error !== undefined &&
      (obj.error = message.error !== undefined ? importerImport_ErrorToJSON(message.error) : undefined);
    message.errorMetadata !== undefined && (obj.errorMetadata = message.errorMetadata);
    message.destinationId !== undefined && (obj.destinationId = message.destinationId);
    message.destinationPath !== undefined && (obj.destinationPath = message.destinationPath);
    message.lastImportVersion !== undefined && (obj.lastImportVersion = Math.round(message.lastImportVersion));
    return obj;
  },

  create<I extends Exact<DeepPartial<ImporterImport>, I>>(base?: I): ImporterImport {
    return ImporterImport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImporterImport>, I>>(object: I): ImporterImport {
    const message = createBaseImporterImport();
    message.id = object.id ?? undefined;
    message.raw = object.raw ?? undefined;
    message.status = object.status ?? undefined;
    message.tryCount = object.tryCount ?? undefined;
    message.userId = object.userId ?? undefined;
    message.importerChannelId = object.importerChannelId ?? undefined;
    message.firstTryStarted = object.firstTryStarted ?? undefined;
    message.lastTryFinished = object.lastTryFinished ?? undefined;
    message.metadata = object.metadata ?? undefined;
    message.error = object.error ?? undefined;
    message.errorMetadata = object.errorMetadata ?? undefined;
    message.destinationId = object.destinationId ?? undefined;
    message.destinationPath = object.destinationPath ?? undefined;
    message.lastImportVersion = object.lastImportVersion ?? undefined;
    return message;
  },
};

function createBaseWebhookChannel(): WebhookChannel {
  return {
    id: undefined,
    status: undefined,
    currentVersion: undefined,
    mapping: undefined,
    groupId: undefined,
    groupName: undefined,
    type: undefined,
    public: undefined,
    userId: undefined,
    name: undefined,
    webhookKey: undefined,
    source: undefined,
    mappingMissing: undefined,
    createdAt: undefined,
    event: undefined,
    endpointType: undefined,
    url: undefined,
    urlBackup: undefined,
    mappingTemplate: undefined,
  };
}

export const WebhookChannel = {
  encode(message: WebhookChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.status !== undefined) {
      writer.uint32(16).int32(message.status);
    }
    if (message.currentVersion !== undefined) {
      writer.uint32(24).int32(message.currentVersion);
    }
    if (message.mapping !== undefined) {
      Struct.encode(Struct.wrap(message.mapping), writer.uint32(34).fork()).ldelim();
    }
    if (message.groupId !== undefined) {
      writer.uint32(42).string(message.groupId);
    }
    if (message.groupName !== undefined) {
      writer.uint32(50).string(message.groupName);
    }
    if (message.type !== undefined) {
      writer.uint32(56).int32(message.type);
    }
    if (message.public !== undefined) {
      writer.uint32(64).bool(message.public);
    }
    if (message.userId !== undefined) {
      writer.uint32(74).string(message.userId);
    }
    if (message.name !== undefined) {
      writer.uint32(82).string(message.name);
    }
    if (message.webhookKey !== undefined) {
      writer.uint32(90).string(message.webhookKey);
    }
    if (message.source !== undefined) {
      writer.uint32(96).int32(message.source);
    }
    if (message.mappingMissing !== undefined) {
      Struct.encode(Struct.wrap(message.mappingMissing), writer.uint32(106).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      DateContainer.encode(message.createdAt, writer.uint32(114).fork()).ldelim();
    }
    if (message.event !== undefined) {
      WebhookChannel_EventContainer.encode(message.event, writer.uint32(122).fork()).ldelim();
    }
    if (message.endpointType !== undefined) {
      writer.uint32(128).int32(message.endpointType);
    }
    if (message.url !== undefined) {
      writer.uint32(138).string(message.url);
    }
    if (message.urlBackup !== undefined) {
      writer.uint32(146).string(message.urlBackup);
    }
    if (message.mappingTemplate !== undefined) {
      writer.uint32(154).string(message.mappingTemplate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.currentVersion = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mapping = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.groupName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.public = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.name = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.webhookKey = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.mappingMissing = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.createdAt = DateContainer.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.event = WebhookChannel_EventContainer.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.endpointType = reader.int32() as any;
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.url = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.urlBackup = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.mappingTemplate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebhookChannel {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      status: isSet(object.status) ? webhookChannel_StatusFromJSON(object.status) : undefined,
      currentVersion: isSet(object.currentVersion) ? Number(object.currentVersion) : undefined,
      mapping: isObject(object.mapping) ? object.mapping : undefined,
      groupId: isSet(object.groupId) ? String(object.groupId) : undefined,
      groupName: isSet(object.groupName) ? String(object.groupName) : undefined,
      type: isSet(object.type) ? typeFromJSON(object.type) : undefined,
      public: isSet(object.public) ? Boolean(object.public) : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      webhookKey: isSet(object.webhookKey) ? String(object.webhookKey) : undefined,
      source: isSet(object.source) ? webhookChannel_SourceFromJSON(object.source) : undefined,
      mappingMissing: isObject(object.mappingMissing) ? object.mappingMissing : undefined,
      createdAt: isSet(object.createdAt) ? DateContainer.fromJSON(object.createdAt) : undefined,
      event: isSet(object.event) ? WebhookChannel_EventContainer.fromJSON(object.event) : undefined,
      endpointType: isSet(object.endpointType) ? httpRequestMethodFromJSON(object.endpointType) : undefined,
      url: isSet(object.url) ? String(object.url) : undefined,
      urlBackup: isSet(object.urlBackup) ? String(object.urlBackup) : undefined,
      mappingTemplate: isSet(object.mappingTemplate) ? String(object.mappingTemplate) : undefined,
    };
  },

  toJSON(message: WebhookChannel): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? webhookChannel_StatusToJSON(message.status) : undefined);
    message.currentVersion !== undefined && (obj.currentVersion = Math.round(message.currentVersion));
    message.mapping !== undefined && (obj.mapping = message.mapping);
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.groupName !== undefined && (obj.groupName = message.groupName);
    message.type !== undefined && (obj.type = message.type !== undefined ? typeToJSON(message.type) : undefined);
    message.public !== undefined && (obj.public = message.public);
    message.userId !== undefined && (obj.userId = message.userId);
    message.name !== undefined && (obj.name = message.name);
    message.webhookKey !== undefined && (obj.webhookKey = message.webhookKey);
    message.source !== undefined &&
      (obj.source = message.source !== undefined ? webhookChannel_SourceToJSON(message.source) : undefined);
    message.mappingMissing !== undefined && (obj.mappingMissing = message.mappingMissing);
    message.createdAt !== undefined &&
      (obj.createdAt = message.createdAt ? DateContainer.toJSON(message.createdAt) : undefined);
    message.event !== undefined &&
      (obj.event = message.event ? WebhookChannel_EventContainer.toJSON(message.event) : undefined);
    message.endpointType !== undefined &&
      (obj.endpointType = message.endpointType !== undefined
        ? httpRequestMethodToJSON(message.endpointType)
        : undefined);
    message.url !== undefined && (obj.url = message.url);
    message.urlBackup !== undefined && (obj.urlBackup = message.urlBackup);
    message.mappingTemplate !== undefined && (obj.mappingTemplate = message.mappingTemplate);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebhookChannel>, I>>(base?: I): WebhookChannel {
    return WebhookChannel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebhookChannel>, I>>(object: I): WebhookChannel {
    const message = createBaseWebhookChannel();
    message.id = object.id ?? undefined;
    message.status = object.status ?? undefined;
    message.currentVersion = object.currentVersion ?? undefined;
    message.mapping = object.mapping ?? undefined;
    message.groupId = object.groupId ?? undefined;
    message.groupName = object.groupName ?? undefined;
    message.type = object.type ?? undefined;
    message.public = object.public ?? undefined;
    message.userId = object.userId ?? undefined;
    message.name = object.name ?? undefined;
    message.webhookKey = object.webhookKey ?? undefined;
    message.source = object.source ?? undefined;
    message.mappingMissing = object.mappingMissing ?? undefined;
    message.createdAt = (object.createdAt !== undefined && object.createdAt !== null)
      ? DateContainer.fromPartial(object.createdAt)
      : undefined;
    message.event = (object.event !== undefined && object.event !== null)
      ? WebhookChannel_EventContainer.fromPartial(object.event)
      : undefined;
    message.endpointType = object.endpointType ?? undefined;
    message.url = object.url ?? undefined;
    message.urlBackup = object.urlBackup ?? undefined;
    message.mappingTemplate = object.mappingTemplate ?? undefined;
    return message;
  },
};

function createBaseWebhookChannel_Mapping(): WebhookChannel_Mapping {
  return {};
}

export const WebhookChannel_Mapping = {
  encode(_: WebhookChannel_Mapping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookChannel_Mapping {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookChannel_Mapping();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): WebhookChannel_Mapping {
    return {};
  },

  toJSON(_: WebhookChannel_Mapping): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<WebhookChannel_Mapping>, I>>(base?: I): WebhookChannel_Mapping {
    return WebhookChannel_Mapping.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebhookChannel_Mapping>, I>>(_: I): WebhookChannel_Mapping {
    const message = createBaseWebhookChannel_Mapping();
    return message;
  },
};

function createBaseWebhookChannel_EventContainer(): WebhookChannel_EventContainer {
  return { ids: [] };
}

export const WebhookChannel_EventContainer = {
  encode(message: WebhookChannel_EventContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.ids) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookChannel_EventContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookChannel_EventContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.ids.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ids.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebhookChannel_EventContainer {
    return { ids: Array.isArray(object?.ids) ? object.ids.map((e: any) => pushEventFromJSON(e)) : [] };
  },

  toJSON(message: WebhookChannel_EventContainer): unknown {
    const obj: any = {};
    if (message.ids) {
      obj.ids = message.ids.map((e) => pushEventToJSON(e));
    } else {
      obj.ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebhookChannel_EventContainer>, I>>(base?: I): WebhookChannel_EventContainer {
    return WebhookChannel_EventContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebhookChannel_EventContainer>, I>>(
    object: I,
  ): WebhookChannel_EventContainer {
    const message = createBaseWebhookChannel_EventContainer();
    message.ids = object.ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseWebhookChannel_File(): WebhookChannel_File {
  return {
    rawPath: undefined,
    chunksCompleted: undefined,
    chunksTotal: undefined,
    createdAt: undefined,
    importStatus: undefined,
    leadsTotal: undefined,
    statusCount: undefined,
    fileName: undefined,
    sheetName: undefined,
    fileSize: undefined,
    lastModified: undefined,
    fileType: undefined,
  };
}

export const WebhookChannel_File = {
  encode(message: WebhookChannel_File, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rawPath !== undefined) {
      writer.uint32(10).string(message.rawPath);
    }
    if (message.chunksCompleted !== undefined) {
      writer.uint32(16).int32(message.chunksCompleted);
    }
    if (message.chunksTotal !== undefined) {
      writer.uint32(24).int32(message.chunksTotal);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(32).int64(message.createdAt);
    }
    if (message.importStatus !== undefined) {
      writer.uint32(42).string(message.importStatus);
    }
    if (message.leadsTotal !== undefined) {
      writer.uint32(48).int32(message.leadsTotal);
    }
    if (message.statusCount !== undefined) {
      WebhookChannel_File_StatusCount.encode(message.statusCount, writer.uint32(58).fork()).ldelim();
    }
    if (message.fileName !== undefined) {
      writer.uint32(66).string(message.fileName);
    }
    if (message.sheetName !== undefined) {
      writer.uint32(74).string(message.sheetName);
    }
    if (message.fileSize !== undefined) {
      writer.uint32(80).int64(message.fileSize);
    }
    if (message.lastModified !== undefined) {
      writer.uint32(88).int64(message.lastModified);
    }
    if (message.fileType !== undefined) {
      writer.uint32(98).string(message.fileType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookChannel_File {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookChannel_File();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rawPath = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.chunksCompleted = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.chunksTotal = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.importStatus = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.leadsTotal = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.statusCount = WebhookChannel_File_StatusCount.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.fileName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.sheetName = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.fileSize = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.lastModified = longToNumber(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.fileType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebhookChannel_File {
    return {
      rawPath: isSet(object.rawPath) ? String(object.rawPath) : undefined,
      chunksCompleted: isSet(object.chunksCompleted) ? Number(object.chunksCompleted) : undefined,
      chunksTotal: isSet(object.chunksTotal) ? Number(object.chunksTotal) : undefined,
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : undefined,
      importStatus: isSet(object.importStatus) ? String(object.importStatus) : undefined,
      leadsTotal: isSet(object.leadsTotal) ? Number(object.leadsTotal) : undefined,
      statusCount: isSet(object.statusCount) ? WebhookChannel_File_StatusCount.fromJSON(object.statusCount) : undefined,
      fileName: isSet(object.fileName) ? String(object.fileName) : undefined,
      sheetName: isSet(object.sheetName) ? String(object.sheetName) : undefined,
      fileSize: isSet(object.fileSize) ? Number(object.fileSize) : undefined,
      lastModified: isSet(object.lastModified) ? Number(object.lastModified) : undefined,
      fileType: isSet(object.fileType) ? String(object.fileType) : undefined,
    };
  },

  toJSON(message: WebhookChannel_File): unknown {
    const obj: any = {};
    message.rawPath !== undefined && (obj.rawPath = message.rawPath);
    message.chunksCompleted !== undefined && (obj.chunksCompleted = Math.round(message.chunksCompleted));
    message.chunksTotal !== undefined && (obj.chunksTotal = Math.round(message.chunksTotal));
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    message.importStatus !== undefined && (obj.importStatus = message.importStatus);
    message.leadsTotal !== undefined && (obj.leadsTotal = Math.round(message.leadsTotal));
    message.statusCount !== undefined &&
      (obj.statusCount = message.statusCount ? WebhookChannel_File_StatusCount.toJSON(message.statusCount) : undefined);
    message.fileName !== undefined && (obj.fileName = message.fileName);
    message.sheetName !== undefined && (obj.sheetName = message.sheetName);
    message.fileSize !== undefined && (obj.fileSize = Math.round(message.fileSize));
    message.lastModified !== undefined && (obj.lastModified = Math.round(message.lastModified));
    message.fileType !== undefined && (obj.fileType = message.fileType);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebhookChannel_File>, I>>(base?: I): WebhookChannel_File {
    return WebhookChannel_File.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebhookChannel_File>, I>>(object: I): WebhookChannel_File {
    const message = createBaseWebhookChannel_File();
    message.rawPath = object.rawPath ?? undefined;
    message.chunksCompleted = object.chunksCompleted ?? undefined;
    message.chunksTotal = object.chunksTotal ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.importStatus = object.importStatus ?? undefined;
    message.leadsTotal = object.leadsTotal ?? undefined;
    message.statusCount = (object.statusCount !== undefined && object.statusCount !== null)
      ? WebhookChannel_File_StatusCount.fromPartial(object.statusCount)
      : undefined;
    message.fileName = object.fileName ?? undefined;
    message.sheetName = object.sheetName ?? undefined;
    message.fileSize = object.fileSize ?? undefined;
    message.lastModified = object.lastModified ?? undefined;
    message.fileType = object.fileType ?? undefined;
    return message;
  },
};

function createBaseWebhookChannel_File_StatusCount(): WebhookChannel_File_StatusCount {
  return { skipped: undefined, success: undefined, error: undefined };
}

export const WebhookChannel_File_StatusCount = {
  encode(message: WebhookChannel_File_StatusCount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.skipped !== undefined) {
      writer.uint32(8).int32(message.skipped);
    }
    if (message.success !== undefined) {
      writer.uint32(16).int32(message.success);
    }
    if (message.error !== undefined) {
      writer.uint32(24).int32(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookChannel_File_StatusCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookChannel_File_StatusCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.skipped = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.success = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.error = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebhookChannel_File_StatusCount {
    return {
      skipped: isSet(object.skipped) ? Number(object.skipped) : undefined,
      success: isSet(object.success) ? Number(object.success) : undefined,
      error: isSet(object.error) ? Number(object.error) : undefined,
    };
  },

  toJSON(message: WebhookChannel_File_StatusCount): unknown {
    const obj: any = {};
    message.skipped !== undefined && (obj.skipped = Math.round(message.skipped));
    message.success !== undefined && (obj.success = Math.round(message.success));
    message.error !== undefined && (obj.error = Math.round(message.error));
    return obj;
  },

  create<I extends Exact<DeepPartial<WebhookChannel_File_StatusCount>, I>>(base?: I): WebhookChannel_File_StatusCount {
    return WebhookChannel_File_StatusCount.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebhookChannel_File_StatusCount>, I>>(
    object: I,
  ): WebhookChannel_File_StatusCount {
    const message = createBaseWebhookChannel_File_StatusCount();
    message.skipped = object.skipped ?? undefined;
    message.success = object.success ?? undefined;
    message.error = object.error ?? undefined;
    return message;
  },
};

function createBaseWebhookRequest(): WebhookRequest {
  return {
    id: undefined,
    raw: undefined,
    status: undefined,
    tryCount: undefined,
    userId: undefined,
    importerChannelId: undefined,
    firstTryStarted: undefined,
    lastTryFinished: undefined,
    metadata: undefined,
    error: undefined,
    errorMetadata: undefined,
    lastImportVersion: undefined,
    tries: [],
    destinationUrl: undefined,
    resultStatusCode: undefined,
  };
}

export const WebhookRequest = {
  encode(message: WebhookRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.raw !== undefined) {
      Struct.encode(Struct.wrap(message.raw), writer.uint32(18).fork()).ldelim();
    }
    if (message.status !== undefined) {
      writer.uint32(24).int32(message.status);
    }
    if (message.tryCount !== undefined) {
      writer.uint32(32).int32(message.tryCount);
    }
    if (message.userId !== undefined) {
      writer.uint32(42).string(message.userId);
    }
    if (message.importerChannelId !== undefined) {
      writer.uint32(50).string(message.importerChannelId);
    }
    if (message.firstTryStarted !== undefined) {
      writer.uint32(56).int64(message.firstTryStarted);
    }
    if (message.lastTryFinished !== undefined) {
      writer.uint32(64).int64(message.lastTryFinished);
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(74).fork()).ldelim();
    }
    if (message.error !== undefined) {
      writer.uint32(80).int32(message.error);
    }
    if (message.errorMetadata !== undefined) {
      Struct.encode(Struct.wrap(message.errorMetadata), writer.uint32(90).fork()).ldelim();
    }
    if (message.lastImportVersion !== undefined) {
      writer.uint32(96).int32(message.lastImportVersion);
    }
    for (const v of message.tries) {
      WebhookRequestTry.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.destinationUrl !== undefined) {
      writer.uint32(114).string(message.destinationUrl);
    }
    if (message.resultStatusCode !== undefined) {
      writer.uint32(122).string(message.resultStatusCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.raw = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tryCount = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.importerChannelId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.firstTryStarted = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.lastTryFinished = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.error = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.errorMetadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.lastImportVersion = reader.int32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.tries.push(WebhookRequestTry.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.destinationUrl = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.resultStatusCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebhookRequest {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      raw: isObject(object.raw) ? object.raw : undefined,
      status: isSet(object.status) ? importerImport_StatusFromJSON(object.status) : undefined,
      tryCount: isSet(object.tryCount) ? Number(object.tryCount) : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      importerChannelId: isSet(object.importerChannelId) ? String(object.importerChannelId) : undefined,
      firstTryStarted: isSet(object.firstTryStarted) ? Number(object.firstTryStarted) : undefined,
      lastTryFinished: isSet(object.lastTryFinished) ? Number(object.lastTryFinished) : undefined,
      metadata: isObject(object.metadata) ? object.metadata : undefined,
      error: isSet(object.error) ? webhookRequestErrorFromJSON(object.error) : undefined,
      errorMetadata: isObject(object.errorMetadata) ? object.errorMetadata : undefined,
      lastImportVersion: isSet(object.lastImportVersion) ? Number(object.lastImportVersion) : undefined,
      tries: Array.isArray(object?.tries) ? object.tries.map((e: any) => WebhookRequestTry.fromJSON(e)) : [],
      destinationUrl: isSet(object.destinationUrl) ? String(object.destinationUrl) : undefined,
      resultStatusCode: isSet(object.resultStatusCode) ? String(object.resultStatusCode) : undefined,
    };
  },

  toJSON(message: WebhookRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.raw !== undefined && (obj.raw = message.raw);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? importerImport_StatusToJSON(message.status) : undefined);
    message.tryCount !== undefined && (obj.tryCount = Math.round(message.tryCount));
    message.userId !== undefined && (obj.userId = message.userId);
    message.importerChannelId !== undefined && (obj.importerChannelId = message.importerChannelId);
    message.firstTryStarted !== undefined && (obj.firstTryStarted = Math.round(message.firstTryStarted));
    message.lastTryFinished !== undefined && (obj.lastTryFinished = Math.round(message.lastTryFinished));
    message.metadata !== undefined && (obj.metadata = message.metadata);
    message.error !== undefined &&
      (obj.error = message.error !== undefined ? webhookRequestErrorToJSON(message.error) : undefined);
    message.errorMetadata !== undefined && (obj.errorMetadata = message.errorMetadata);
    message.lastImportVersion !== undefined && (obj.lastImportVersion = Math.round(message.lastImportVersion));
    if (message.tries) {
      obj.tries = message.tries.map((e) => e ? WebhookRequestTry.toJSON(e) : undefined);
    } else {
      obj.tries = [];
    }
    message.destinationUrl !== undefined && (obj.destinationUrl = message.destinationUrl);
    message.resultStatusCode !== undefined && (obj.resultStatusCode = message.resultStatusCode);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebhookRequest>, I>>(base?: I): WebhookRequest {
    return WebhookRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebhookRequest>, I>>(object: I): WebhookRequest {
    const message = createBaseWebhookRequest();
    message.id = object.id ?? undefined;
    message.raw = object.raw ?? undefined;
    message.status = object.status ?? undefined;
    message.tryCount = object.tryCount ?? undefined;
    message.userId = object.userId ?? undefined;
    message.importerChannelId = object.importerChannelId ?? undefined;
    message.firstTryStarted = object.firstTryStarted ?? undefined;
    message.lastTryFinished = object.lastTryFinished ?? undefined;
    message.metadata = object.metadata ?? undefined;
    message.error = object.error ?? undefined;
    message.errorMetadata = object.errorMetadata ?? undefined;
    message.lastImportVersion = object.lastImportVersion ?? undefined;
    message.tries = object.tries?.map((e) => WebhookRequestTry.fromPartial(e)) || [];
    message.destinationUrl = object.destinationUrl ?? undefined;
    message.resultStatusCode = object.resultStatusCode ?? undefined;
    return message;
  },
};

function createBaseWebhookRequestTry(): WebhookRequestTry {
  return { status: undefined, headers: undefined, body: undefined, tryFinished: undefined, name: undefined };
}

export const WebhookRequestTry = {
  encode(message: WebhookRequestTry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== undefined) {
      writer.uint32(10).string(message.status);
    }
    if (message.headers !== undefined) {
      Struct.encode(Struct.wrap(message.headers), writer.uint32(18).fork()).ldelim();
    }
    if (message.body !== undefined) {
      Struct.encode(Struct.wrap(message.body), writer.uint32(26).fork()).ldelim();
    }
    if (message.tryFinished !== undefined) {
      writer.uint32(32).int64(message.tryFinished);
    }
    if (message.name !== undefined) {
      writer.uint32(42).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookRequestTry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookRequestTry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.headers = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.body = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tryFinished = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebhookRequestTry {
    return {
      status: isSet(object.status) ? String(object.status) : undefined,
      headers: isObject(object.headers) ? object.headers : undefined,
      body: isObject(object.body) ? object.body : undefined,
      tryFinished: isSet(object.tryFinished) ? Number(object.tryFinished) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: WebhookRequestTry): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status);
    message.headers !== undefined && (obj.headers = message.headers);
    message.body !== undefined && (obj.body = message.body);
    message.tryFinished !== undefined && (obj.tryFinished = Math.round(message.tryFinished));
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebhookRequestTry>, I>>(base?: I): WebhookRequestTry {
    return WebhookRequestTry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebhookRequestTry>, I>>(object: I): WebhookRequestTry {
    const message = createBaseWebhookRequestTry();
    message.status = object.status ?? undefined;
    message.headers = object.headers ?? undefined;
    message.body = object.body ?? undefined;
    message.tryFinished = object.tryFinished ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseWebhookEventBody(): WebhookEventBody {
  return { user: undefined, type: undefined, event: undefined, data: undefined, timestamp: undefined };
}

export const WebhookEventBody = {
  encode(message: WebhookEventBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      UserIdContainer.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(16).int32(message.type);
    }
    if (message.event !== undefined) {
      writer.uint32(24).int32(message.event);
    }
    if (message.data !== undefined) {
      Struct.encode(Struct.wrap(message.data), writer.uint32(34).fork()).ldelim();
    }
    if (message.timestamp !== undefined) {
      writer.uint32(40).int64(message.timestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookEventBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookEventBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.event = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.data = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.timestamp = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebhookEventBody {
    return {
      user: isSet(object.user) ? UserIdContainer.fromJSON(object.user) : undefined,
      type: isSet(object.type) ? typeFromJSON(object.type) : undefined,
      event: isSet(object.event) ? pushEventFromJSON(object.event) : undefined,
      data: isObject(object.data) ? object.data : undefined,
      timestamp: isSet(object.timestamp) ? Number(object.timestamp) : undefined,
    };
  },

  toJSON(message: WebhookEventBody): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user ? UserIdContainer.toJSON(message.user) : undefined);
    message.type !== undefined && (obj.type = message.type !== undefined ? typeToJSON(message.type) : undefined);
    message.event !== undefined &&
      (obj.event = message.event !== undefined ? pushEventToJSON(message.event) : undefined);
    message.data !== undefined && (obj.data = message.data);
    message.timestamp !== undefined && (obj.timestamp = Math.round(message.timestamp));
    return obj;
  },

  create<I extends Exact<DeepPartial<WebhookEventBody>, I>>(base?: I): WebhookEventBody {
    return WebhookEventBody.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebhookEventBody>, I>>(object: I): WebhookEventBody {
    const message = createBaseWebhookEventBody();
    message.user = (object.user !== undefined && object.user !== null)
      ? UserIdContainer.fromPartial(object.user)
      : undefined;
    message.type = object.type ?? undefined;
    message.event = object.event ?? undefined;
    message.data = object.data ?? undefined;
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
