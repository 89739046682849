/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Agent } from "./agent";
import { Carrier } from "./carrier";
import { CommissionType, commissionTypeFromJSON, commissionTypeToJSON } from "./commissions-enum";
import { Consumer } from "./consumer";
import { MedicarePlan_PlanType, medicarePlan_PlanTypeFromJSON, medicarePlan_PlanTypeToJSON } from "./enrollment";
import { AdvancedPayoutRule, AggregatedExecutiveRuleCommissions, ExecutiveLadderRule } from "./executive";
import { Struct } from "./google/protobuf/struct";
import {
  CommissionCycle,
  DateContainer,
  FieldUpdateContainer,
  IdentifierContainer,
  PolicyUpdateContainer,
  TagsContainer,
  TierFilterListElement,
} from "./misc";

export const protobufPackage = "";

export enum CommissionStatus {
  IMPORT_STATUS_UNKNOWN = 0,
  CS_PENDING = 1,
  CS_PENDING_PROCESS_BATCH = 2,
  CS_SUCCESS = 10,
  CS_ERROR = 20,
  CS_MISSING_IDENTIFIED_RESULT = 21,
  CS_MISSING_IDENTIFIED_RESULT_SINGLE = 22,
  CS_WAITING_FOR_BATCH = 31,
  UNRECOGNIZED = -1,
}

export function commissionStatusFromJSON(object: any): CommissionStatus {
  switch (object) {
    case 0:
    case "IMPORT_STATUS_UNKNOWN":
      return CommissionStatus.IMPORT_STATUS_UNKNOWN;
    case 1:
    case "CS_PENDING":
      return CommissionStatus.CS_PENDING;
    case 2:
    case "CS_PENDING_PROCESS_BATCH":
      return CommissionStatus.CS_PENDING_PROCESS_BATCH;
    case 10:
    case "CS_SUCCESS":
      return CommissionStatus.CS_SUCCESS;
    case 20:
    case "CS_ERROR":
      return CommissionStatus.CS_ERROR;
    case 21:
    case "CS_MISSING_IDENTIFIED_RESULT":
      return CommissionStatus.CS_MISSING_IDENTIFIED_RESULT;
    case 22:
    case "CS_MISSING_IDENTIFIED_RESULT_SINGLE":
      return CommissionStatus.CS_MISSING_IDENTIFIED_RESULT_SINGLE;
    case 31:
    case "CS_WAITING_FOR_BATCH":
      return CommissionStatus.CS_WAITING_FOR_BATCH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CommissionStatus.UNRECOGNIZED;
  }
}

export function commissionStatusToJSON(object: CommissionStatus): string {
  switch (object) {
    case CommissionStatus.IMPORT_STATUS_UNKNOWN:
      return "IMPORT_STATUS_UNKNOWN";
    case CommissionStatus.CS_PENDING:
      return "CS_PENDING";
    case CommissionStatus.CS_PENDING_PROCESS_BATCH:
      return "CS_PENDING_PROCESS_BATCH";
    case CommissionStatus.CS_SUCCESS:
      return "CS_SUCCESS";
    case CommissionStatus.CS_ERROR:
      return "CS_ERROR";
    case CommissionStatus.CS_MISSING_IDENTIFIED_RESULT:
      return "CS_MISSING_IDENTIFIED_RESULT";
    case CommissionStatus.CS_MISSING_IDENTIFIED_RESULT_SINGLE:
      return "CS_MISSING_IDENTIFIED_RESULT_SINGLE";
    case CommissionStatus.CS_WAITING_FOR_BATCH:
      return "CS_WAITING_FOR_BATCH";
    case CommissionStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Commission {
  id?:
    | string
    | undefined;
  /** -> pflicht, */
  policyId?:
    | string
    | undefined;
  /** verknüpfen wenn möglich, sonst neu erstellen wenn möglich */
  consumer?:
    | Consumer
    | undefined;
  /** pflicht, erkennen über npn oder writing id (wenn writing id unbekannt, dann fehler -> pfelegen) */
  agent?:
    | Agent
    | undefined;
  /** pflicht, wird manuell ausgewählt oder gemapped */
  carrier?:
    | Carrier
    | undefined;
  /** pflicht, wird manuell ausgewählt oder gemapped */
  type?:
    | CommissionType
    | undefined;
  /** pflicht, wird manuell ausgewählt oder gemapped */
  origin?:
    | Commission_Origin
    | undefined;
  /** initial replacement */
  rate?:
    | Commission_Rate
    | undefined;
  /** pflicht */
  effectiveDate?:
    | DateContainer
    | undefined;
  /** process date */
  statementDate?: DateContainer | undefined;
  terminationDate?: DateContainer | undefined;
  paymentDate?:
    | DateContainer
    | undefined;
  /** pflicht */
  signatureDate?: DateContainer | undefined;
  state?:
    | string
    | undefined;
  /** optional int32 year = 15; */
  planType?:
    | MedicarePlan_PlanType
    | undefined;
  /** pflicht */
  planTypeCommission?: Commission_PlanType | undefined;
  level?:
    | string
    | undefined;
  /** pflicht , gross com paid, total owed */
  commissionAmount?: number | undefined;
  preprocessing?: Commission_Preprocessing | undefined;
  identifiedResult?: Commission_IdentifiedResult | undefined;
  possibleIdentifiedResult: Commission_IdentifiedResult[];
  agency?: Commission_AgencyInner | undefined;
  aggregatedExecutiveRuleCommissions?: AggregatedExecutiveRuleCommissions | undefined;
  error?: Commission_Error | undefined;
  status?: CommissionStatus | undefined;
  payout?: Commission_Payout | undefined;
  managingAgent?: Commission_AgencyInner | undefined;
  user?: Commission_AgencyInner | undefined;
  commission?: Commission_CommissionInner | undefined;
  executive?: Commission_CommissionsExecutive | undefined;
  channel?: Commission_CommissionChannel | undefined;
  cycle?: CommissionCycle | undefined;
  custom?: Commission_CommissionCustom | undefined;
  group?: Commission_CommissionGroup | undefined;
  fieldUpdates?: FieldUpdateContainer | undefined;
  identifiers?: IdentifierContainer | undefined;
  payer?: Commission_AgencyInner | undefined;
  policyUpdates?: PolicyUpdateContainer | undefined;
  tags?: TagsContainer | undefined;
  customData?: Commission_CustomData | undefined;
  allowCombinations?: boolean | undefined;
}

export enum Commission_PlanType {
  CPT_NONE = 0,
  CPT_PDP = 1,
  CPT_MAPD_MA = 2,
  CPT_CUSTOM = 99,
  UNRECOGNIZED = -1,
}

export function commission_PlanTypeFromJSON(object: any): Commission_PlanType {
  switch (object) {
    case 0:
    case "CPT_NONE":
      return Commission_PlanType.CPT_NONE;
    case 1:
    case "CPT_PDP":
      return Commission_PlanType.CPT_PDP;
    case 2:
    case "CPT_MAPD_MA":
      return Commission_PlanType.CPT_MAPD_MA;
    case 99:
    case "CPT_CUSTOM":
      return Commission_PlanType.CPT_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Commission_PlanType.UNRECOGNIZED;
  }
}

export function commission_PlanTypeToJSON(object: Commission_PlanType): string {
  switch (object) {
    case Commission_PlanType.CPT_NONE:
      return "CPT_NONE";
    case Commission_PlanType.CPT_PDP:
      return "CPT_PDP";
    case Commission_PlanType.CPT_MAPD_MA:
      return "CPT_MAPD_MA";
    case Commission_PlanType.CPT_CUSTOM:
      return "CPT_CUSTOM";
    case Commission_PlanType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Commission_Rate {
  RT_NONE = 0,
  RT_INITIAL = 1,
  RT_REPLACEMENT = 2,
  RT_INITIAL_FYC = 3,
  RT_MONTHLY_RENEWAL = 4,
  RT_MONTHLY_RENEWAL_AFTER_3_YEARS = 5,
  RT_YEARLY_RENEWAL = 6,
  RT_INITIAL_TRUE_UP_PRORATED = 7,
  RT_INITIAL_TRUE_UP_FULLYEAR = 8,
  RT_INITIAL_FULLYEAR = 9,
  RT_REPLACEMENT_FULLYEAR = 10,
  RT_INITIAL_CC = 11,
  RT_REPLACEMENT_CC = 12,
  RT_MONTHLY_RENEWAL_CC = 13,
  RT_INITIAL_CC_FULLYEAR = 14,
  RT_REPLACEMENT_CC_FULLYEAR = 15,
  RT_HEAP_INITIAL = 31,
  RT_HEAP_RECON = 32,
  RT_HEAP_RECON_CHARGEBACK = 33,
  RT_CUSTOM = 99,
  UNRECOGNIZED = -1,
}

export function commission_RateFromJSON(object: any): Commission_Rate {
  switch (object) {
    case 0:
    case "RT_NONE":
      return Commission_Rate.RT_NONE;
    case 1:
    case "RT_INITIAL":
      return Commission_Rate.RT_INITIAL;
    case 2:
    case "RT_REPLACEMENT":
      return Commission_Rate.RT_REPLACEMENT;
    case 3:
    case "RT_INITIAL_FYC":
      return Commission_Rate.RT_INITIAL_FYC;
    case 4:
    case "RT_MONTHLY_RENEWAL":
      return Commission_Rate.RT_MONTHLY_RENEWAL;
    case 5:
    case "RT_MONTHLY_RENEWAL_AFTER_3_YEARS":
      return Commission_Rate.RT_MONTHLY_RENEWAL_AFTER_3_YEARS;
    case 6:
    case "RT_YEARLY_RENEWAL":
      return Commission_Rate.RT_YEARLY_RENEWAL;
    case 7:
    case "RT_INITIAL_TRUE_UP_PRORATED":
      return Commission_Rate.RT_INITIAL_TRUE_UP_PRORATED;
    case 8:
    case "RT_INITIAL_TRUE_UP_FULLYEAR":
      return Commission_Rate.RT_INITIAL_TRUE_UP_FULLYEAR;
    case 9:
    case "RT_INITIAL_FULLYEAR":
      return Commission_Rate.RT_INITIAL_FULLYEAR;
    case 10:
    case "RT_REPLACEMENT_FULLYEAR":
      return Commission_Rate.RT_REPLACEMENT_FULLYEAR;
    case 11:
    case "RT_INITIAL_CC":
      return Commission_Rate.RT_INITIAL_CC;
    case 12:
    case "RT_REPLACEMENT_CC":
      return Commission_Rate.RT_REPLACEMENT_CC;
    case 13:
    case "RT_MONTHLY_RENEWAL_CC":
      return Commission_Rate.RT_MONTHLY_RENEWAL_CC;
    case 14:
    case "RT_INITIAL_CC_FULLYEAR":
      return Commission_Rate.RT_INITIAL_CC_FULLYEAR;
    case 15:
    case "RT_REPLACEMENT_CC_FULLYEAR":
      return Commission_Rate.RT_REPLACEMENT_CC_FULLYEAR;
    case 31:
    case "RT_HEAP_INITIAL":
      return Commission_Rate.RT_HEAP_INITIAL;
    case 32:
    case "RT_HEAP_RECON":
      return Commission_Rate.RT_HEAP_RECON;
    case 33:
    case "RT_HEAP_RECON_CHARGEBACK":
      return Commission_Rate.RT_HEAP_RECON_CHARGEBACK;
    case 99:
    case "RT_CUSTOM":
      return Commission_Rate.RT_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Commission_Rate.UNRECOGNIZED;
  }
}

export function commission_RateToJSON(object: Commission_Rate): string {
  switch (object) {
    case Commission_Rate.RT_NONE:
      return "RT_NONE";
    case Commission_Rate.RT_INITIAL:
      return "RT_INITIAL";
    case Commission_Rate.RT_REPLACEMENT:
      return "RT_REPLACEMENT";
    case Commission_Rate.RT_INITIAL_FYC:
      return "RT_INITIAL_FYC";
    case Commission_Rate.RT_MONTHLY_RENEWAL:
      return "RT_MONTHLY_RENEWAL";
    case Commission_Rate.RT_MONTHLY_RENEWAL_AFTER_3_YEARS:
      return "RT_MONTHLY_RENEWAL_AFTER_3_YEARS";
    case Commission_Rate.RT_YEARLY_RENEWAL:
      return "RT_YEARLY_RENEWAL";
    case Commission_Rate.RT_INITIAL_TRUE_UP_PRORATED:
      return "RT_INITIAL_TRUE_UP_PRORATED";
    case Commission_Rate.RT_INITIAL_TRUE_UP_FULLYEAR:
      return "RT_INITIAL_TRUE_UP_FULLYEAR";
    case Commission_Rate.RT_INITIAL_FULLYEAR:
      return "RT_INITIAL_FULLYEAR";
    case Commission_Rate.RT_REPLACEMENT_FULLYEAR:
      return "RT_REPLACEMENT_FULLYEAR";
    case Commission_Rate.RT_INITIAL_CC:
      return "RT_INITIAL_CC";
    case Commission_Rate.RT_REPLACEMENT_CC:
      return "RT_REPLACEMENT_CC";
    case Commission_Rate.RT_MONTHLY_RENEWAL_CC:
      return "RT_MONTHLY_RENEWAL_CC";
    case Commission_Rate.RT_INITIAL_CC_FULLYEAR:
      return "RT_INITIAL_CC_FULLYEAR";
    case Commission_Rate.RT_REPLACEMENT_CC_FULLYEAR:
      return "RT_REPLACEMENT_CC_FULLYEAR";
    case Commission_Rate.RT_HEAP_INITIAL:
      return "RT_HEAP_INITIAL";
    case Commission_Rate.RT_HEAP_RECON:
      return "RT_HEAP_RECON";
    case Commission_Rate.RT_HEAP_RECON_CHARGEBACK:
      return "RT_HEAP_RECON_CHARGEBACK";
    case Commission_Rate.RT_CUSTOM:
      return "RT_CUSTOM";
    case Commission_Rate.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Commission_Origin {
  OR_NONE = 0,
  OR_DIRECT_FROM_CARRIER = 1,
  OR_FROM_PARENT = 2,
  UNRECOGNIZED = -1,
}

export function commission_OriginFromJSON(object: any): Commission_Origin {
  switch (object) {
    case 0:
    case "OR_NONE":
      return Commission_Origin.OR_NONE;
    case 1:
    case "OR_DIRECT_FROM_CARRIER":
      return Commission_Origin.OR_DIRECT_FROM_CARRIER;
    case 2:
    case "OR_FROM_PARENT":
      return Commission_Origin.OR_FROM_PARENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Commission_Origin.UNRECOGNIZED;
  }
}

export function commission_OriginToJSON(object: Commission_Origin): string {
  switch (object) {
    case Commission_Origin.OR_NONE:
      return "OR_NONE";
    case Commission_Origin.OR_DIRECT_FROM_CARRIER:
      return "OR_DIRECT_FROM_CARRIER";
    case Commission_Origin.OR_FROM_PARENT:
      return "OR_FROM_PARENT";
    case Commission_Origin.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Commission_OperationType {
  OT_NONE = 0,
  OT_SUBTRACTION = 1,
  OT_ADDITION = 2,
  UNRECOGNIZED = -1,
}

export function commission_OperationTypeFromJSON(object: any): Commission_OperationType {
  switch (object) {
    case 0:
    case "OT_NONE":
      return Commission_OperationType.OT_NONE;
    case 1:
    case "OT_SUBTRACTION":
      return Commission_OperationType.OT_SUBTRACTION;
    case 2:
    case "OT_ADDITION":
      return Commission_OperationType.OT_ADDITION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Commission_OperationType.UNRECOGNIZED;
  }
}

export function commission_OperationTypeToJSON(object: Commission_OperationType): string {
  switch (object) {
    case Commission_OperationType.OT_NONE:
      return "OT_NONE";
    case Commission_OperationType.OT_SUBTRACTION:
      return "OT_SUBTRACTION";
    case Commission_OperationType.OT_ADDITION:
      return "OT_ADDITION";
    case Commission_OperationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Commission_CommissionsExecutive {
  aggregatedExecutiveRuleCommissions?: AggregatedExecutiveRuleCommissions | undefined;
  tierMap?: { [key: string]: any } | undefined;
  tierFilterList: TierFilterListElement[];
}

export interface Commission_CommissionCustom {
  isCustom?: boolean | undefined;
  setByUserId?: string | undefined;
  updatedAt?: DateContainer | undefined;
}

export interface Commission_CustomData {
  map: { [key: string]: string };
}

export interface Commission_CustomData_MapEntry {
  key: string;
  value: string;
}

export interface Commission_CommissionChannel {
  uploadDate?: DateContainer | undefined;
  id?: string | undefined;
  name?: string | undefined;
  agencyId?: string | undefined;
}

export interface Commission_CommissionInner {
  id?: string | undefined;
  agency?: Commission_AgencyInner | undefined;
}

export interface Commission_CommissionGroup {
  isParent?: boolean | undefined;
  isChild?: boolean | undefined;
  parentId?: string | undefined;
  childrenIds: string[];
  parentIds: string[];
}

export interface Commission_AgencyInner {
  id?: string | undefined;
}

export interface Commission_Error {
  code?: Commission_Error_Code | undefined;
  message?: string | undefined;
  details?: { [key: string]: any } | undefined;
}

export enum Commission_Error_Code {
  CE_UNKNOWN = 0,
  CE_INTERNAL = 1,
  UNRECOGNIZED = -1,
}

export function commission_Error_CodeFromJSON(object: any): Commission_Error_Code {
  switch (object) {
    case 0:
    case "CE_UNKNOWN":
      return Commission_Error_Code.CE_UNKNOWN;
    case 1:
    case "CE_INTERNAL":
      return Commission_Error_Code.CE_INTERNAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Commission_Error_Code.UNRECOGNIZED;
  }
}

export function commission_Error_CodeToJSON(object: Commission_Error_Code): string {
  switch (object) {
    case Commission_Error_Code.CE_UNKNOWN:
      return "CE_UNKNOWN";
    case Commission_Error_Code.CE_INTERNAL:
      return "CE_INTERNAL";
    case Commission_Error_Code.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Commission_Payout {
  byUser: { [key: string]: number };
  total?: number | undefined;
  personal?: number | undefined;
  childSum?: number | undefined;
  appliedAdvancedPayoutRules?: Commission_AppliedAdvancedPayoutRulesFrom | undefined;
}

export interface Commission_Payout_ByUserEntry {
  key: string;
  value: number;
}

export interface Commission_AppliedAdvancedPayoutRulesFrom {
  from: { [key: string]: AdvancedPayoutRule };
}

export interface Commission_AppliedAdvancedPayoutRulesFrom_FromEntry {
  key: string;
  value: AdvancedPayoutRule | undefined;
}

export interface Commission_AdvancedPayoutRuleTo {
  to: { [key: string]: AdvancedPayoutRule };
}

export interface Commission_AdvancedPayoutRuleTo_ToEntry {
  key: string;
  value: AdvancedPayoutRule | undefined;
}

export interface Commission_IdentifiedResult {
  df1?: Commission_DfValue | undefined;
  df2?:
    | Commission_DfValue
    | undefined;
  /** Type of the operation, e.g., 'subtraction', 'addition' */
  operationType?:
    | Commission_OperationType
    | undefined;
  /** Descriptive message about the operation */
  operationMessage?:
    | string
    | undefined;
  /** Indicates if the result of the operation is considered negative */
  isNegative?: boolean | undefined;
  pennyTolerance?: number | undefined;
  pennyToleranceOwner?: string | undefined;
  monthCountChargeback?: number | undefined;
}

export interface Commission_ColumnDetail {
  planType?: Commission_PlanType | undefined;
  rate?: Commission_Rate | undefined;
  state?: string | undefined;
  carrierId?: string | undefined;
  comment?: string | undefined;
  level?: string | undefined;
  value?: number | undefined;
}

/** PayerPayoutRuleContainer */
export interface Commission_CarrierPayoutRuleContainer {
  /** payerPayoutRules */
  carrierPayoutRules: Commission_CarrierPayoutRule[];
}

export interface Commission_CarrierPayoutRule {
  type?: CommissionType | undefined;
  versionName?: string | undefined;
  versionID?: string | undefined;
  ladderRule?: ExecutiveLadderRule | undefined;
  payoutTable?: Commission_PayoutTable | undefined;
}

export interface Commission_PayoutTable {
  columnNames: string[];
  lockedLevelFromCarrier: string[];
  table: Commission_ColumnDetail[];
}

export interface Commission_DfValue {
  dfInfo?: Commission_CarrierPayoutRule | undefined;
  value?: number | undefined;
  row?: string | undefined;
  column?: string | undefined;
  columnDetail?: Commission_ColumnDetail | undefined;
}

/** immer default settings + wert aus row, duplicates raus */
export interface Commission_Preprocessing {
  /** copy from carrier */
  carrierId: string[];
  /** PDP oder MAPD, MA */
  planTypeCommission: string[];
  state: string[];
  /** initial replacement */
  rate: string[];
  /** welches level hat agency beim carruer + default settings */
  level: string[];
  /** level aus agent und agency account */
  year: string[];
  enrollmentPolicyStatus: string[];
}

function createBaseCommission(): Commission {
  return {
    id: undefined,
    policyId: undefined,
    consumer: undefined,
    agent: undefined,
    carrier: undefined,
    type: undefined,
    origin: undefined,
    rate: undefined,
    effectiveDate: undefined,
    statementDate: undefined,
    terminationDate: undefined,
    paymentDate: undefined,
    signatureDate: undefined,
    state: undefined,
    planType: undefined,
    planTypeCommission: undefined,
    level: undefined,
    commissionAmount: undefined,
    preprocessing: undefined,
    identifiedResult: undefined,
    possibleIdentifiedResult: [],
    agency: undefined,
    aggregatedExecutiveRuleCommissions: undefined,
    error: undefined,
    status: undefined,
    payout: undefined,
    managingAgent: undefined,
    user: undefined,
    commission: undefined,
    executive: undefined,
    channel: undefined,
    cycle: undefined,
    custom: undefined,
    group: undefined,
    fieldUpdates: undefined,
    identifiers: undefined,
    payer: undefined,
    policyUpdates: undefined,
    tags: undefined,
    customData: undefined,
    allowCombinations: undefined,
  };
}

export const Commission = {
  encode(message: Commission, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.policyId !== undefined) {
      writer.uint32(18).string(message.policyId);
    }
    if (message.consumer !== undefined) {
      Consumer.encode(message.consumer, writer.uint32(26).fork()).ldelim();
    }
    if (message.agent !== undefined) {
      Agent.encode(message.agent, writer.uint32(34).fork()).ldelim();
    }
    if (message.carrier !== undefined) {
      Carrier.encode(message.carrier, writer.uint32(42).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(48).int32(message.type);
    }
    if (message.origin !== undefined) {
      writer.uint32(56).int32(message.origin);
    }
    if (message.rate !== undefined) {
      writer.uint32(64).int32(message.rate);
    }
    if (message.effectiveDate !== undefined) {
      DateContainer.encode(message.effectiveDate, writer.uint32(74).fork()).ldelim();
    }
    if (message.statementDate !== undefined) {
      DateContainer.encode(message.statementDate, writer.uint32(82).fork()).ldelim();
    }
    if (message.terminationDate !== undefined) {
      DateContainer.encode(message.terminationDate, writer.uint32(90).fork()).ldelim();
    }
    if (message.paymentDate !== undefined) {
      DateContainer.encode(message.paymentDate, writer.uint32(98).fork()).ldelim();
    }
    if (message.signatureDate !== undefined) {
      DateContainer.encode(message.signatureDate, writer.uint32(106).fork()).ldelim();
    }
    if (message.state !== undefined) {
      writer.uint32(114).string(message.state);
    }
    if (message.planType !== undefined) {
      writer.uint32(128).int32(message.planType);
    }
    if (message.planTypeCommission !== undefined) {
      writer.uint32(136).int32(message.planTypeCommission);
    }
    if (message.level !== undefined) {
      writer.uint32(146).string(message.level);
    }
    if (message.commissionAmount !== undefined) {
      writer.uint32(157).float(message.commissionAmount);
    }
    if (message.preprocessing !== undefined) {
      Commission_Preprocessing.encode(message.preprocessing, writer.uint32(162).fork()).ldelim();
    }
    if (message.identifiedResult !== undefined) {
      Commission_IdentifiedResult.encode(message.identifiedResult, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.possibleIdentifiedResult) {
      Commission_IdentifiedResult.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      Commission_AgencyInner.encode(message.agency, writer.uint32(186).fork()).ldelim();
    }
    if (message.aggregatedExecutiveRuleCommissions !== undefined) {
      AggregatedExecutiveRuleCommissions.encode(message.aggregatedExecutiveRuleCommissions, writer.uint32(194).fork())
        .ldelim();
    }
    if (message.error !== undefined) {
      Commission_Error.encode(message.error, writer.uint32(202).fork()).ldelim();
    }
    if (message.status !== undefined) {
      writer.uint32(208).int32(message.status);
    }
    if (message.payout !== undefined) {
      Commission_Payout.encode(message.payout, writer.uint32(218).fork()).ldelim();
    }
    if (message.managingAgent !== undefined) {
      Commission_AgencyInner.encode(message.managingAgent, writer.uint32(226).fork()).ldelim();
    }
    if (message.user !== undefined) {
      Commission_AgencyInner.encode(message.user, writer.uint32(234).fork()).ldelim();
    }
    if (message.commission !== undefined) {
      Commission_CommissionInner.encode(message.commission, writer.uint32(242).fork()).ldelim();
    }
    if (message.executive !== undefined) {
      Commission_CommissionsExecutive.encode(message.executive, writer.uint32(250).fork()).ldelim();
    }
    if (message.channel !== undefined) {
      Commission_CommissionChannel.encode(message.channel, writer.uint32(258).fork()).ldelim();
    }
    if (message.cycle !== undefined) {
      CommissionCycle.encode(message.cycle, writer.uint32(266).fork()).ldelim();
    }
    if (message.custom !== undefined) {
      Commission_CommissionCustom.encode(message.custom, writer.uint32(274).fork()).ldelim();
    }
    if (message.group !== undefined) {
      Commission_CommissionGroup.encode(message.group, writer.uint32(282).fork()).ldelim();
    }
    if (message.fieldUpdates !== undefined) {
      FieldUpdateContainer.encode(message.fieldUpdates, writer.uint32(290).fork()).ldelim();
    }
    if (message.identifiers !== undefined) {
      IdentifierContainer.encode(message.identifiers, writer.uint32(298).fork()).ldelim();
    }
    if (message.payer !== undefined) {
      Commission_AgencyInner.encode(message.payer, writer.uint32(306).fork()).ldelim();
    }
    if (message.policyUpdates !== undefined) {
      PolicyUpdateContainer.encode(message.policyUpdates, writer.uint32(314).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      TagsContainer.encode(message.tags, writer.uint32(322).fork()).ldelim();
    }
    if (message.customData !== undefined) {
      Commission_CustomData.encode(message.customData, writer.uint32(330).fork()).ldelim();
    }
    if (message.allowCombinations !== undefined) {
      writer.uint32(336).bool(message.allowCombinations);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.policyId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.consumer = Consumer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.agent = Agent.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.carrier = Carrier.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.origin = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.rate = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.effectiveDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.statementDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.terminationDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.paymentDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.signatureDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.state = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.planType = reader.int32() as any;
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.planTypeCommission = reader.int32() as any;
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.level = reader.string();
          continue;
        case 19:
          if (tag !== 157) {
            break;
          }

          message.commissionAmount = reader.float();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.preprocessing = Commission_Preprocessing.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.identifiedResult = Commission_IdentifiedResult.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.possibleIdentifiedResult.push(Commission_IdentifiedResult.decode(reader, reader.uint32()));
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.agency = Commission_AgencyInner.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.aggregatedExecutiveRuleCommissions = AggregatedExecutiveRuleCommissions.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.error = Commission_Error.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.payout = Commission_Payout.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.managingAgent = Commission_AgencyInner.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.user = Commission_AgencyInner.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.commission = Commission_CommissionInner.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.executive = Commission_CommissionsExecutive.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.channel = Commission_CommissionChannel.decode(reader, reader.uint32());
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.cycle = CommissionCycle.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.custom = Commission_CommissionCustom.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.group = Commission_CommissionGroup.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.fieldUpdates = FieldUpdateContainer.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.identifiers = IdentifierContainer.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.payer = Commission_AgencyInner.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.policyUpdates = PolicyUpdateContainer.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.tags = TagsContainer.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.customData = Commission_CustomData.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag !== 336) {
            break;
          }

          message.allowCombinations = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      policyId: isSet(object.policyId) ? String(object.policyId) : undefined,
      consumer: isSet(object.consumer) ? Consumer.fromJSON(object.consumer) : undefined,
      agent: isSet(object.agent) ? Agent.fromJSON(object.agent) : undefined,
      carrier: isSet(object.carrier) ? Carrier.fromJSON(object.carrier) : undefined,
      type: isSet(object.type) ? commissionTypeFromJSON(object.type) : undefined,
      origin: isSet(object.origin) ? commission_OriginFromJSON(object.origin) : undefined,
      rate: isSet(object.rate) ? commission_RateFromJSON(object.rate) : undefined,
      effectiveDate: isSet(object.effectiveDate) ? DateContainer.fromJSON(object.effectiveDate) : undefined,
      statementDate: isSet(object.statementDate) ? DateContainer.fromJSON(object.statementDate) : undefined,
      terminationDate: isSet(object.terminationDate) ? DateContainer.fromJSON(object.terminationDate) : undefined,
      paymentDate: isSet(object.paymentDate) ? DateContainer.fromJSON(object.paymentDate) : undefined,
      signatureDate: isSet(object.signatureDate) ? DateContainer.fromJSON(object.signatureDate) : undefined,
      state: isSet(object.state) ? String(object.state) : undefined,
      planType: isSet(object.planType) ? medicarePlan_PlanTypeFromJSON(object.planType) : undefined,
      planTypeCommission: isSet(object.planTypeCommission)
        ? commission_PlanTypeFromJSON(object.planTypeCommission)
        : undefined,
      level: isSet(object.level) ? String(object.level) : undefined,
      commissionAmount: isSet(object.commissionAmount) ? Number(object.commissionAmount) : undefined,
      preprocessing: isSet(object.preprocessing) ? Commission_Preprocessing.fromJSON(object.preprocessing) : undefined,
      identifiedResult: isSet(object.identifiedResult)
        ? Commission_IdentifiedResult.fromJSON(object.identifiedResult)
        : undefined,
      possibleIdentifiedResult: Array.isArray(object?.possibleIdentifiedResult)
        ? object.possibleIdentifiedResult.map((e: any) => Commission_IdentifiedResult.fromJSON(e))
        : [],
      agency: isSet(object.agency) ? Commission_AgencyInner.fromJSON(object.agency) : undefined,
      aggregatedExecutiveRuleCommissions: isSet(object.aggregatedExecutiveRuleCommissions)
        ? AggregatedExecutiveRuleCommissions.fromJSON(object.aggregatedExecutiveRuleCommissions)
        : undefined,
      error: isSet(object.error) ? Commission_Error.fromJSON(object.error) : undefined,
      status: isSet(object.status) ? commissionStatusFromJSON(object.status) : undefined,
      payout: isSet(object.payout) ? Commission_Payout.fromJSON(object.payout) : undefined,
      managingAgent: isSet(object.managingAgent) ? Commission_AgencyInner.fromJSON(object.managingAgent) : undefined,
      user: isSet(object.user) ? Commission_AgencyInner.fromJSON(object.user) : undefined,
      commission: isSet(object.commission) ? Commission_CommissionInner.fromJSON(object.commission) : undefined,
      executive: isSet(object.executive) ? Commission_CommissionsExecutive.fromJSON(object.executive) : undefined,
      channel: isSet(object.channel) ? Commission_CommissionChannel.fromJSON(object.channel) : undefined,
      cycle: isSet(object.cycle) ? CommissionCycle.fromJSON(object.cycle) : undefined,
      custom: isSet(object.custom) ? Commission_CommissionCustom.fromJSON(object.custom) : undefined,
      group: isSet(object.group) ? Commission_CommissionGroup.fromJSON(object.group) : undefined,
      fieldUpdates: isSet(object.fieldUpdates) ? FieldUpdateContainer.fromJSON(object.fieldUpdates) : undefined,
      identifiers: isSet(object.identifiers) ? IdentifierContainer.fromJSON(object.identifiers) : undefined,
      payer: isSet(object.payer) ? Commission_AgencyInner.fromJSON(object.payer) : undefined,
      policyUpdates: isSet(object.policyUpdates) ? PolicyUpdateContainer.fromJSON(object.policyUpdates) : undefined,
      tags: isSet(object.tags) ? TagsContainer.fromJSON(object.tags) : undefined,
      customData: isSet(object.customData) ? Commission_CustomData.fromJSON(object.customData) : undefined,
      allowCombinations: isSet(object.allowCombinations) ? Boolean(object.allowCombinations) : undefined,
    };
  },

  toJSON(message: Commission): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.policyId !== undefined && (obj.policyId = message.policyId);
    message.consumer !== undefined && (obj.consumer = message.consumer ? Consumer.toJSON(message.consumer) : undefined);
    message.agent !== undefined && (obj.agent = message.agent ? Agent.toJSON(message.agent) : undefined);
    message.carrier !== undefined && (obj.carrier = message.carrier ? Carrier.toJSON(message.carrier) : undefined);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? commissionTypeToJSON(message.type) : undefined);
    message.origin !== undefined &&
      (obj.origin = message.origin !== undefined ? commission_OriginToJSON(message.origin) : undefined);
    message.rate !== undefined &&
      (obj.rate = message.rate !== undefined ? commission_RateToJSON(message.rate) : undefined);
    message.effectiveDate !== undefined &&
      (obj.effectiveDate = message.effectiveDate ? DateContainer.toJSON(message.effectiveDate) : undefined);
    message.statementDate !== undefined &&
      (obj.statementDate = message.statementDate ? DateContainer.toJSON(message.statementDate) : undefined);
    message.terminationDate !== undefined &&
      (obj.terminationDate = message.terminationDate ? DateContainer.toJSON(message.terminationDate) : undefined);
    message.paymentDate !== undefined &&
      (obj.paymentDate = message.paymentDate ? DateContainer.toJSON(message.paymentDate) : undefined);
    message.signatureDate !== undefined &&
      (obj.signatureDate = message.signatureDate ? DateContainer.toJSON(message.signatureDate) : undefined);
    message.state !== undefined && (obj.state = message.state);
    message.planType !== undefined &&
      (obj.planType = message.planType !== undefined ? medicarePlan_PlanTypeToJSON(message.planType) : undefined);
    message.planTypeCommission !== undefined && (obj.planTypeCommission = message.planTypeCommission !== undefined
      ? commission_PlanTypeToJSON(message.planTypeCommission)
      : undefined);
    message.level !== undefined && (obj.level = message.level);
    message.commissionAmount !== undefined && (obj.commissionAmount = message.commissionAmount);
    message.preprocessing !== undefined &&
      (obj.preprocessing = message.preprocessing ? Commission_Preprocessing.toJSON(message.preprocessing) : undefined);
    message.identifiedResult !== undefined && (obj.identifiedResult = message.identifiedResult
      ? Commission_IdentifiedResult.toJSON(message.identifiedResult)
      : undefined);
    if (message.possibleIdentifiedResult) {
      obj.possibleIdentifiedResult = message.possibleIdentifiedResult.map((e) =>
        e ? Commission_IdentifiedResult.toJSON(e) : undefined
      );
    } else {
      obj.possibleIdentifiedResult = [];
    }
    message.agency !== undefined &&
      (obj.agency = message.agency ? Commission_AgencyInner.toJSON(message.agency) : undefined);
    message.aggregatedExecutiveRuleCommissions !== undefined &&
      (obj.aggregatedExecutiveRuleCommissions = message.aggregatedExecutiveRuleCommissions
        ? AggregatedExecutiveRuleCommissions.toJSON(message.aggregatedExecutiveRuleCommissions)
        : undefined);
    message.error !== undefined && (obj.error = message.error ? Commission_Error.toJSON(message.error) : undefined);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? commissionStatusToJSON(message.status) : undefined);
    message.payout !== undefined &&
      (obj.payout = message.payout ? Commission_Payout.toJSON(message.payout) : undefined);
    message.managingAgent !== undefined &&
      (obj.managingAgent = message.managingAgent ? Commission_AgencyInner.toJSON(message.managingAgent) : undefined);
    message.user !== undefined && (obj.user = message.user ? Commission_AgencyInner.toJSON(message.user) : undefined);
    message.commission !== undefined &&
      (obj.commission = message.commission ? Commission_CommissionInner.toJSON(message.commission) : undefined);
    message.executive !== undefined &&
      (obj.executive = message.executive ? Commission_CommissionsExecutive.toJSON(message.executive) : undefined);
    message.channel !== undefined &&
      (obj.channel = message.channel ? Commission_CommissionChannel.toJSON(message.channel) : undefined);
    message.cycle !== undefined && (obj.cycle = message.cycle ? CommissionCycle.toJSON(message.cycle) : undefined);
    message.custom !== undefined &&
      (obj.custom = message.custom ? Commission_CommissionCustom.toJSON(message.custom) : undefined);
    message.group !== undefined &&
      (obj.group = message.group ? Commission_CommissionGroup.toJSON(message.group) : undefined);
    message.fieldUpdates !== undefined &&
      (obj.fieldUpdates = message.fieldUpdates ? FieldUpdateContainer.toJSON(message.fieldUpdates) : undefined);
    message.identifiers !== undefined &&
      (obj.identifiers = message.identifiers ? IdentifierContainer.toJSON(message.identifiers) : undefined);
    message.payer !== undefined &&
      (obj.payer = message.payer ? Commission_AgencyInner.toJSON(message.payer) : undefined);
    message.policyUpdates !== undefined &&
      (obj.policyUpdates = message.policyUpdates ? PolicyUpdateContainer.toJSON(message.policyUpdates) : undefined);
    message.tags !== undefined && (obj.tags = message.tags ? TagsContainer.toJSON(message.tags) : undefined);
    message.customData !== undefined &&
      (obj.customData = message.customData ? Commission_CustomData.toJSON(message.customData) : undefined);
    message.allowCombinations !== undefined && (obj.allowCombinations = message.allowCombinations);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission>, I>>(base?: I): Commission {
    return Commission.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission>, I>>(object: I): Commission {
    const message = createBaseCommission();
    message.id = object.id ?? undefined;
    message.policyId = object.policyId ?? undefined;
    message.consumer = (object.consumer !== undefined && object.consumer !== null)
      ? Consumer.fromPartial(object.consumer)
      : undefined;
    message.agent = (object.agent !== undefined && object.agent !== null) ? Agent.fromPartial(object.agent) : undefined;
    message.carrier = (object.carrier !== undefined && object.carrier !== null)
      ? Carrier.fromPartial(object.carrier)
      : undefined;
    message.type = object.type ?? undefined;
    message.origin = object.origin ?? undefined;
    message.rate = object.rate ?? undefined;
    message.effectiveDate = (object.effectiveDate !== undefined && object.effectiveDate !== null)
      ? DateContainer.fromPartial(object.effectiveDate)
      : undefined;
    message.statementDate = (object.statementDate !== undefined && object.statementDate !== null)
      ? DateContainer.fromPartial(object.statementDate)
      : undefined;
    message.terminationDate = (object.terminationDate !== undefined && object.terminationDate !== null)
      ? DateContainer.fromPartial(object.terminationDate)
      : undefined;
    message.paymentDate = (object.paymentDate !== undefined && object.paymentDate !== null)
      ? DateContainer.fromPartial(object.paymentDate)
      : undefined;
    message.signatureDate = (object.signatureDate !== undefined && object.signatureDate !== null)
      ? DateContainer.fromPartial(object.signatureDate)
      : undefined;
    message.state = object.state ?? undefined;
    message.planType = object.planType ?? undefined;
    message.planTypeCommission = object.planTypeCommission ?? undefined;
    message.level = object.level ?? undefined;
    message.commissionAmount = object.commissionAmount ?? undefined;
    message.preprocessing = (object.preprocessing !== undefined && object.preprocessing !== null)
      ? Commission_Preprocessing.fromPartial(object.preprocessing)
      : undefined;
    message.identifiedResult = (object.identifiedResult !== undefined && object.identifiedResult !== null)
      ? Commission_IdentifiedResult.fromPartial(object.identifiedResult)
      : undefined;
    message.possibleIdentifiedResult =
      object.possibleIdentifiedResult?.map((e) => Commission_IdentifiedResult.fromPartial(e)) || [];
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? Commission_AgencyInner.fromPartial(object.agency)
      : undefined;
    message.aggregatedExecutiveRuleCommissions =
      (object.aggregatedExecutiveRuleCommissions !== undefined && object.aggregatedExecutiveRuleCommissions !== null)
        ? AggregatedExecutiveRuleCommissions.fromPartial(object.aggregatedExecutiveRuleCommissions)
        : undefined;
    message.error = (object.error !== undefined && object.error !== null)
      ? Commission_Error.fromPartial(object.error)
      : undefined;
    message.status = object.status ?? undefined;
    message.payout = (object.payout !== undefined && object.payout !== null)
      ? Commission_Payout.fromPartial(object.payout)
      : undefined;
    message.managingAgent = (object.managingAgent !== undefined && object.managingAgent !== null)
      ? Commission_AgencyInner.fromPartial(object.managingAgent)
      : undefined;
    message.user = (object.user !== undefined && object.user !== null)
      ? Commission_AgencyInner.fromPartial(object.user)
      : undefined;
    message.commission = (object.commission !== undefined && object.commission !== null)
      ? Commission_CommissionInner.fromPartial(object.commission)
      : undefined;
    message.executive = (object.executive !== undefined && object.executive !== null)
      ? Commission_CommissionsExecutive.fromPartial(object.executive)
      : undefined;
    message.channel = (object.channel !== undefined && object.channel !== null)
      ? Commission_CommissionChannel.fromPartial(object.channel)
      : undefined;
    message.cycle = (object.cycle !== undefined && object.cycle !== null)
      ? CommissionCycle.fromPartial(object.cycle)
      : undefined;
    message.custom = (object.custom !== undefined && object.custom !== null)
      ? Commission_CommissionCustom.fromPartial(object.custom)
      : undefined;
    message.group = (object.group !== undefined && object.group !== null)
      ? Commission_CommissionGroup.fromPartial(object.group)
      : undefined;
    message.fieldUpdates = (object.fieldUpdates !== undefined && object.fieldUpdates !== null)
      ? FieldUpdateContainer.fromPartial(object.fieldUpdates)
      : undefined;
    message.identifiers = (object.identifiers !== undefined && object.identifiers !== null)
      ? IdentifierContainer.fromPartial(object.identifiers)
      : undefined;
    message.payer = (object.payer !== undefined && object.payer !== null)
      ? Commission_AgencyInner.fromPartial(object.payer)
      : undefined;
    message.policyUpdates = (object.policyUpdates !== undefined && object.policyUpdates !== null)
      ? PolicyUpdateContainer.fromPartial(object.policyUpdates)
      : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? TagsContainer.fromPartial(object.tags)
      : undefined;
    message.customData = (object.customData !== undefined && object.customData !== null)
      ? Commission_CustomData.fromPartial(object.customData)
      : undefined;
    message.allowCombinations = object.allowCombinations ?? undefined;
    return message;
  },
};

function createBaseCommission_CommissionsExecutive(): Commission_CommissionsExecutive {
  return { aggregatedExecutiveRuleCommissions: undefined, tierMap: undefined, tierFilterList: [] };
}

export const Commission_CommissionsExecutive = {
  encode(message: Commission_CommissionsExecutive, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.aggregatedExecutiveRuleCommissions !== undefined) {
      AggregatedExecutiveRuleCommissions.encode(message.aggregatedExecutiveRuleCommissions, writer.uint32(10).fork())
        .ldelim();
    }
    if (message.tierMap !== undefined) {
      Struct.encode(Struct.wrap(message.tierMap), writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.tierFilterList) {
      TierFilterListElement.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_CommissionsExecutive {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_CommissionsExecutive();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aggregatedExecutiveRuleCommissions = AggregatedExecutiveRuleCommissions.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tierMap = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tierFilterList.push(TierFilterListElement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_CommissionsExecutive {
    return {
      aggregatedExecutiveRuleCommissions: isSet(object.aggregatedExecutiveRuleCommissions)
        ? AggregatedExecutiveRuleCommissions.fromJSON(object.aggregatedExecutiveRuleCommissions)
        : undefined,
      tierMap: isObject(object.tierMap) ? object.tierMap : undefined,
      tierFilterList: Array.isArray(object?.tierFilterList)
        ? object.tierFilterList.map((e: any) => TierFilterListElement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Commission_CommissionsExecutive): unknown {
    const obj: any = {};
    message.aggregatedExecutiveRuleCommissions !== undefined &&
      (obj.aggregatedExecutiveRuleCommissions = message.aggregatedExecutiveRuleCommissions
        ? AggregatedExecutiveRuleCommissions.toJSON(message.aggregatedExecutiveRuleCommissions)
        : undefined);
    message.tierMap !== undefined && (obj.tierMap = message.tierMap);
    if (message.tierFilterList) {
      obj.tierFilterList = message.tierFilterList.map((e) => e ? TierFilterListElement.toJSON(e) : undefined);
    } else {
      obj.tierFilterList = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_CommissionsExecutive>, I>>(base?: I): Commission_CommissionsExecutive {
    return Commission_CommissionsExecutive.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_CommissionsExecutive>, I>>(
    object: I,
  ): Commission_CommissionsExecutive {
    const message = createBaseCommission_CommissionsExecutive();
    message.aggregatedExecutiveRuleCommissions =
      (object.aggregatedExecutiveRuleCommissions !== undefined && object.aggregatedExecutiveRuleCommissions !== null)
        ? AggregatedExecutiveRuleCommissions.fromPartial(object.aggregatedExecutiveRuleCommissions)
        : undefined;
    message.tierMap = object.tierMap ?? undefined;
    message.tierFilterList = object.tierFilterList?.map((e) => TierFilterListElement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCommission_CommissionCustom(): Commission_CommissionCustom {
  return { isCustom: undefined, setByUserId: undefined, updatedAt: undefined };
}

export const Commission_CommissionCustom = {
  encode(message: Commission_CommissionCustom, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isCustom !== undefined) {
      writer.uint32(8).bool(message.isCustom);
    }
    if (message.setByUserId !== undefined) {
      writer.uint32(18).string(message.setByUserId);
    }
    if (message.updatedAt !== undefined) {
      DateContainer.encode(message.updatedAt, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_CommissionCustom {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_CommissionCustom();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isCustom = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.setByUserId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedAt = DateContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_CommissionCustom {
    return {
      isCustom: isSet(object.isCustom) ? Boolean(object.isCustom) : undefined,
      setByUserId: isSet(object.setByUserId) ? String(object.setByUserId) : undefined,
      updatedAt: isSet(object.updatedAt) ? DateContainer.fromJSON(object.updatedAt) : undefined,
    };
  },

  toJSON(message: Commission_CommissionCustom): unknown {
    const obj: any = {};
    message.isCustom !== undefined && (obj.isCustom = message.isCustom);
    message.setByUserId !== undefined && (obj.setByUserId = message.setByUserId);
    message.updatedAt !== undefined &&
      (obj.updatedAt = message.updatedAt ? DateContainer.toJSON(message.updatedAt) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_CommissionCustom>, I>>(base?: I): Commission_CommissionCustom {
    return Commission_CommissionCustom.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_CommissionCustom>, I>>(object: I): Commission_CommissionCustom {
    const message = createBaseCommission_CommissionCustom();
    message.isCustom = object.isCustom ?? undefined;
    message.setByUserId = object.setByUserId ?? undefined;
    message.updatedAt = (object.updatedAt !== undefined && object.updatedAt !== null)
      ? DateContainer.fromPartial(object.updatedAt)
      : undefined;
    return message;
  },
};

function createBaseCommission_CustomData(): Commission_CustomData {
  return { map: {} };
}

export const Commission_CustomData = {
  encode(message: Commission_CustomData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.map).forEach(([key, value]) => {
      Commission_CustomData_MapEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_CustomData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_CustomData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = Commission_CustomData_MapEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.map[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_CustomData {
    return {
      map: isObject(object.map)
        ? Object.entries(object.map).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Commission_CustomData): unknown {
    const obj: any = {};
    obj.map = {};
    if (message.map) {
      Object.entries(message.map).forEach(([k, v]) => {
        obj.map[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_CustomData>, I>>(base?: I): Commission_CustomData {
    return Commission_CustomData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_CustomData>, I>>(object: I): Commission_CustomData {
    const message = createBaseCommission_CustomData();
    message.map = Object.entries(object.map ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseCommission_CustomData_MapEntry(): Commission_CustomData_MapEntry {
  return { key: "", value: "" };
}

export const Commission_CustomData_MapEntry = {
  encode(message: Commission_CustomData_MapEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_CustomData_MapEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_CustomData_MapEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_CustomData_MapEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: Commission_CustomData_MapEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_CustomData_MapEntry>, I>>(base?: I): Commission_CustomData_MapEntry {
    return Commission_CustomData_MapEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_CustomData_MapEntry>, I>>(
    object: I,
  ): Commission_CustomData_MapEntry {
    const message = createBaseCommission_CustomData_MapEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseCommission_CommissionChannel(): Commission_CommissionChannel {
  return { uploadDate: undefined, id: undefined, name: undefined, agencyId: undefined };
}

export const Commission_CommissionChannel = {
  encode(message: Commission_CommissionChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uploadDate !== undefined) {
      DateContainer.encode(message.uploadDate, writer.uint32(10).fork()).ldelim();
    }
    if (message.id !== undefined) {
      writer.uint32(18).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.agencyId !== undefined) {
      writer.uint32(34).string(message.agencyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_CommissionChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_CommissionChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uploadDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.agencyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_CommissionChannel {
    return {
      uploadDate: isSet(object.uploadDate) ? DateContainer.fromJSON(object.uploadDate) : undefined,
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
    };
  },

  toJSON(message: Commission_CommissionChannel): unknown {
    const obj: any = {};
    message.uploadDate !== undefined &&
      (obj.uploadDate = message.uploadDate ? DateContainer.toJSON(message.uploadDate) : undefined);
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_CommissionChannel>, I>>(base?: I): Commission_CommissionChannel {
    return Commission_CommissionChannel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_CommissionChannel>, I>>(object: I): Commission_CommissionChannel {
    const message = createBaseCommission_CommissionChannel();
    message.uploadDate = (object.uploadDate !== undefined && object.uploadDate !== null)
      ? DateContainer.fromPartial(object.uploadDate)
      : undefined;
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.agencyId = object.agencyId ?? undefined;
    return message;
  },
};

function createBaseCommission_CommissionInner(): Commission_CommissionInner {
  return { id: undefined, agency: undefined };
}

export const Commission_CommissionInner = {
  encode(message: Commission_CommissionInner, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.agency !== undefined) {
      Commission_AgencyInner.encode(message.agency, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_CommissionInner {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_CommissionInner();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agency = Commission_AgencyInner.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_CommissionInner {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      agency: isSet(object.agency) ? Commission_AgencyInner.fromJSON(object.agency) : undefined,
    };
  },

  toJSON(message: Commission_CommissionInner): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.agency !== undefined &&
      (obj.agency = message.agency ? Commission_AgencyInner.toJSON(message.agency) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_CommissionInner>, I>>(base?: I): Commission_CommissionInner {
    return Commission_CommissionInner.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_CommissionInner>, I>>(object: I): Commission_CommissionInner {
    const message = createBaseCommission_CommissionInner();
    message.id = object.id ?? undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? Commission_AgencyInner.fromPartial(object.agency)
      : undefined;
    return message;
  },
};

function createBaseCommission_CommissionGroup(): Commission_CommissionGroup {
  return { isParent: undefined, isChild: undefined, parentId: undefined, childrenIds: [], parentIds: [] };
}

export const Commission_CommissionGroup = {
  encode(message: Commission_CommissionGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isParent !== undefined) {
      writer.uint32(8).bool(message.isParent);
    }
    if (message.isChild !== undefined) {
      writer.uint32(16).bool(message.isChild);
    }
    if (message.parentId !== undefined) {
      writer.uint32(26).string(message.parentId);
    }
    for (const v of message.childrenIds) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.parentIds) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_CommissionGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_CommissionGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isParent = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isChild = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.childrenIds.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.parentIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_CommissionGroup {
    return {
      isParent: isSet(object.isParent) ? Boolean(object.isParent) : undefined,
      isChild: isSet(object.isChild) ? Boolean(object.isChild) : undefined,
      parentId: isSet(object.parentId) ? String(object.parentId) : undefined,
      childrenIds: Array.isArray(object?.childrenIds) ? object.childrenIds.map((e: any) => String(e)) : [],
      parentIds: Array.isArray(object?.parentIds) ? object.parentIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: Commission_CommissionGroup): unknown {
    const obj: any = {};
    message.isParent !== undefined && (obj.isParent = message.isParent);
    message.isChild !== undefined && (obj.isChild = message.isChild);
    message.parentId !== undefined && (obj.parentId = message.parentId);
    if (message.childrenIds) {
      obj.childrenIds = message.childrenIds.map((e) => e);
    } else {
      obj.childrenIds = [];
    }
    if (message.parentIds) {
      obj.parentIds = message.parentIds.map((e) => e);
    } else {
      obj.parentIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_CommissionGroup>, I>>(base?: I): Commission_CommissionGroup {
    return Commission_CommissionGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_CommissionGroup>, I>>(object: I): Commission_CommissionGroup {
    const message = createBaseCommission_CommissionGroup();
    message.isParent = object.isParent ?? undefined;
    message.isChild = object.isChild ?? undefined;
    message.parentId = object.parentId ?? undefined;
    message.childrenIds = object.childrenIds?.map((e) => e) || [];
    message.parentIds = object.parentIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseCommission_AgencyInner(): Commission_AgencyInner {
  return { id: undefined };
}

export const Commission_AgencyInner = {
  encode(message: Commission_AgencyInner, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_AgencyInner {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_AgencyInner();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_AgencyInner {
    return { id: isSet(object.id) ? String(object.id) : undefined };
  },

  toJSON(message: Commission_AgencyInner): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_AgencyInner>, I>>(base?: I): Commission_AgencyInner {
    return Commission_AgencyInner.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_AgencyInner>, I>>(object: I): Commission_AgencyInner {
    const message = createBaseCommission_AgencyInner();
    message.id = object.id ?? undefined;
    return message;
  },
};

function createBaseCommission_Error(): Commission_Error {
  return { code: undefined, message: undefined, details: undefined };
}

export const Commission_Error = {
  encode(message: Commission_Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== undefined) {
      writer.uint32(16).int32(message.code);
    }
    if (message.message !== undefined) {
      writer.uint32(10).string(message.message);
    }
    if (message.details !== undefined) {
      Struct.encode(Struct.wrap(message.details), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_Error {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_Error();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.details = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_Error {
    return {
      code: isSet(object.code) ? commission_Error_CodeFromJSON(object.code) : undefined,
      message: isSet(object.message) ? String(object.message) : undefined,
      details: isObject(object.details) ? object.details : undefined,
    };
  },

  toJSON(message: Commission_Error): unknown {
    const obj: any = {};
    message.code !== undefined &&
      (obj.code = message.code !== undefined ? commission_Error_CodeToJSON(message.code) : undefined);
    message.message !== undefined && (obj.message = message.message);
    message.details !== undefined && (obj.details = message.details);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_Error>, I>>(base?: I): Commission_Error {
    return Commission_Error.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_Error>, I>>(object: I): Commission_Error {
    const message = createBaseCommission_Error();
    message.code = object.code ?? undefined;
    message.message = object.message ?? undefined;
    message.details = object.details ?? undefined;
    return message;
  },
};

function createBaseCommission_Payout(): Commission_Payout {
  return {
    byUser: {},
    total: undefined,
    personal: undefined,
    childSum: undefined,
    appliedAdvancedPayoutRules: undefined,
  };
}

export const Commission_Payout = {
  encode(message: Commission_Payout, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.byUser).forEach(([key, value]) => {
      Commission_Payout_ByUserEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.total !== undefined) {
      writer.uint32(21).float(message.total);
    }
    if (message.personal !== undefined) {
      writer.uint32(29).float(message.personal);
    }
    if (message.childSum !== undefined) {
      writer.uint32(37).float(message.childSum);
    }
    if (message.appliedAdvancedPayoutRules !== undefined) {
      Commission_AppliedAdvancedPayoutRulesFrom.encode(message.appliedAdvancedPayoutRules, writer.uint32(42).fork())
        .ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_Payout {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_Payout();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = Commission_Payout_ByUserEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.byUser[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.total = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.personal = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.childSum = reader.float();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.appliedAdvancedPayoutRules = Commission_AppliedAdvancedPayoutRulesFrom.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_Payout {
    return {
      byUser: isObject(object.byUser)
        ? Object.entries(object.byUser).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      total: isSet(object.total) ? Number(object.total) : undefined,
      personal: isSet(object.personal) ? Number(object.personal) : undefined,
      childSum: isSet(object.childSum) ? Number(object.childSum) : undefined,
      appliedAdvancedPayoutRules: isSet(object.appliedAdvancedPayoutRules)
        ? Commission_AppliedAdvancedPayoutRulesFrom.fromJSON(object.appliedAdvancedPayoutRules)
        : undefined,
    };
  },

  toJSON(message: Commission_Payout): unknown {
    const obj: any = {};
    obj.byUser = {};
    if (message.byUser) {
      Object.entries(message.byUser).forEach(([k, v]) => {
        obj.byUser[k] = v;
      });
    }
    message.total !== undefined && (obj.total = message.total);
    message.personal !== undefined && (obj.personal = message.personal);
    message.childSum !== undefined && (obj.childSum = message.childSum);
    message.appliedAdvancedPayoutRules !== undefined &&
      (obj.appliedAdvancedPayoutRules = message.appliedAdvancedPayoutRules
        ? Commission_AppliedAdvancedPayoutRulesFrom.toJSON(message.appliedAdvancedPayoutRules)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_Payout>, I>>(base?: I): Commission_Payout {
    return Commission_Payout.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_Payout>, I>>(object: I): Commission_Payout {
    const message = createBaseCommission_Payout();
    message.byUser = Object.entries(object.byUser ?? {}).reduce<{ [key: string]: number }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Number(value);
      }
      return acc;
    }, {});
    message.total = object.total ?? undefined;
    message.personal = object.personal ?? undefined;
    message.childSum = object.childSum ?? undefined;
    message.appliedAdvancedPayoutRules =
      (object.appliedAdvancedPayoutRules !== undefined && object.appliedAdvancedPayoutRules !== null)
        ? Commission_AppliedAdvancedPayoutRulesFrom.fromPartial(object.appliedAdvancedPayoutRules)
        : undefined;
    return message;
  },
};

function createBaseCommission_Payout_ByUserEntry(): Commission_Payout_ByUserEntry {
  return { key: "", value: 0 };
}

export const Commission_Payout_ByUserEntry = {
  encode(message: Commission_Payout_ByUserEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_Payout_ByUserEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_Payout_ByUserEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_Payout_ByUserEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: Commission_Payout_ByUserEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_Payout_ByUserEntry>, I>>(base?: I): Commission_Payout_ByUserEntry {
    return Commission_Payout_ByUserEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_Payout_ByUserEntry>, I>>(
    object: I,
  ): Commission_Payout_ByUserEntry {
    const message = createBaseCommission_Payout_ByUserEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseCommission_AppliedAdvancedPayoutRulesFrom(): Commission_AppliedAdvancedPayoutRulesFrom {
  return { from: {} };
}

export const Commission_AppliedAdvancedPayoutRulesFrom = {
  encode(message: Commission_AppliedAdvancedPayoutRulesFrom, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.from).forEach(([key, value]) => {
      Commission_AppliedAdvancedPayoutRulesFrom_FromEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_AppliedAdvancedPayoutRulesFrom {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_AppliedAdvancedPayoutRulesFrom();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = Commission_AppliedAdvancedPayoutRulesFrom_FromEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.from[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_AppliedAdvancedPayoutRulesFrom {
    return {
      from: isObject(object.from)
        ? Object.entries(object.from).reduce<{ [key: string]: AdvancedPayoutRule }>((acc, [key, value]) => {
          acc[key] = AdvancedPayoutRule.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Commission_AppliedAdvancedPayoutRulesFrom): unknown {
    const obj: any = {};
    obj.from = {};
    if (message.from) {
      Object.entries(message.from).forEach(([k, v]) => {
        obj.from[k] = AdvancedPayoutRule.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_AppliedAdvancedPayoutRulesFrom>, I>>(
    base?: I,
  ): Commission_AppliedAdvancedPayoutRulesFrom {
    return Commission_AppliedAdvancedPayoutRulesFrom.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_AppliedAdvancedPayoutRulesFrom>, I>>(
    object: I,
  ): Commission_AppliedAdvancedPayoutRulesFrom {
    const message = createBaseCommission_AppliedAdvancedPayoutRulesFrom();
    message.from = Object.entries(object.from ?? {}).reduce<{ [key: string]: AdvancedPayoutRule }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = AdvancedPayoutRule.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseCommission_AppliedAdvancedPayoutRulesFrom_FromEntry(): Commission_AppliedAdvancedPayoutRulesFrom_FromEntry {
  return { key: "", value: undefined };
}

export const Commission_AppliedAdvancedPayoutRulesFrom_FromEntry = {
  encode(
    message: Commission_AppliedAdvancedPayoutRulesFrom_FromEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      AdvancedPayoutRule.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_AppliedAdvancedPayoutRulesFrom_FromEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_AppliedAdvancedPayoutRulesFrom_FromEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = AdvancedPayoutRule.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_AppliedAdvancedPayoutRulesFrom_FromEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? AdvancedPayoutRule.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Commission_AppliedAdvancedPayoutRulesFrom_FromEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? AdvancedPayoutRule.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_AppliedAdvancedPayoutRulesFrom_FromEntry>, I>>(
    base?: I,
  ): Commission_AppliedAdvancedPayoutRulesFrom_FromEntry {
    return Commission_AppliedAdvancedPayoutRulesFrom_FromEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_AppliedAdvancedPayoutRulesFrom_FromEntry>, I>>(
    object: I,
  ): Commission_AppliedAdvancedPayoutRulesFrom_FromEntry {
    const message = createBaseCommission_AppliedAdvancedPayoutRulesFrom_FromEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? AdvancedPayoutRule.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseCommission_AdvancedPayoutRuleTo(): Commission_AdvancedPayoutRuleTo {
  return { to: {} };
}

export const Commission_AdvancedPayoutRuleTo = {
  encode(message: Commission_AdvancedPayoutRuleTo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.to).forEach(([key, value]) => {
      Commission_AdvancedPayoutRuleTo_ToEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_AdvancedPayoutRuleTo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_AdvancedPayoutRuleTo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = Commission_AdvancedPayoutRuleTo_ToEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.to[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_AdvancedPayoutRuleTo {
    return {
      to: isObject(object.to)
        ? Object.entries(object.to).reduce<{ [key: string]: AdvancedPayoutRule }>((acc, [key, value]) => {
          acc[key] = AdvancedPayoutRule.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Commission_AdvancedPayoutRuleTo): unknown {
    const obj: any = {};
    obj.to = {};
    if (message.to) {
      Object.entries(message.to).forEach(([k, v]) => {
        obj.to[k] = AdvancedPayoutRule.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_AdvancedPayoutRuleTo>, I>>(base?: I): Commission_AdvancedPayoutRuleTo {
    return Commission_AdvancedPayoutRuleTo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_AdvancedPayoutRuleTo>, I>>(
    object: I,
  ): Commission_AdvancedPayoutRuleTo {
    const message = createBaseCommission_AdvancedPayoutRuleTo();
    message.to = Object.entries(object.to ?? {}).reduce<{ [key: string]: AdvancedPayoutRule }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = AdvancedPayoutRule.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseCommission_AdvancedPayoutRuleTo_ToEntry(): Commission_AdvancedPayoutRuleTo_ToEntry {
  return { key: "", value: undefined };
}

export const Commission_AdvancedPayoutRuleTo_ToEntry = {
  encode(message: Commission_AdvancedPayoutRuleTo_ToEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      AdvancedPayoutRule.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_AdvancedPayoutRuleTo_ToEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_AdvancedPayoutRuleTo_ToEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = AdvancedPayoutRule.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_AdvancedPayoutRuleTo_ToEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? AdvancedPayoutRule.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Commission_AdvancedPayoutRuleTo_ToEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? AdvancedPayoutRule.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_AdvancedPayoutRuleTo_ToEntry>, I>>(
    base?: I,
  ): Commission_AdvancedPayoutRuleTo_ToEntry {
    return Commission_AdvancedPayoutRuleTo_ToEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_AdvancedPayoutRuleTo_ToEntry>, I>>(
    object: I,
  ): Commission_AdvancedPayoutRuleTo_ToEntry {
    const message = createBaseCommission_AdvancedPayoutRuleTo_ToEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? AdvancedPayoutRule.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseCommission_IdentifiedResult(): Commission_IdentifiedResult {
  return {
    df1: undefined,
    df2: undefined,
    operationType: undefined,
    operationMessage: undefined,
    isNegative: undefined,
    pennyTolerance: undefined,
    pennyToleranceOwner: undefined,
    monthCountChargeback: undefined,
  };
}

export const Commission_IdentifiedResult = {
  encode(message: Commission_IdentifiedResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.df1 !== undefined) {
      Commission_DfValue.encode(message.df1, writer.uint32(10).fork()).ldelim();
    }
    if (message.df2 !== undefined) {
      Commission_DfValue.encode(message.df2, writer.uint32(18).fork()).ldelim();
    }
    if (message.operationType !== undefined) {
      writer.uint32(24).int32(message.operationType);
    }
    if (message.operationMessage !== undefined) {
      writer.uint32(34).string(message.operationMessage);
    }
    if (message.isNegative !== undefined) {
      writer.uint32(40).bool(message.isNegative);
    }
    if (message.pennyTolerance !== undefined) {
      writer.uint32(53).float(message.pennyTolerance);
    }
    if (message.pennyToleranceOwner !== undefined) {
      writer.uint32(58).string(message.pennyToleranceOwner);
    }
    if (message.monthCountChargeback !== undefined) {
      writer.uint32(64).int32(message.monthCountChargeback);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_IdentifiedResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_IdentifiedResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.df1 = Commission_DfValue.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.df2 = Commission_DfValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.operationType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.operationMessage = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isNegative = reader.bool();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.pennyTolerance = reader.float();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.pennyToleranceOwner = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.monthCountChargeback = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_IdentifiedResult {
    return {
      df1: isSet(object.df1) ? Commission_DfValue.fromJSON(object.df1) : undefined,
      df2: isSet(object.df2) ? Commission_DfValue.fromJSON(object.df2) : undefined,
      operationType: isSet(object.operationType) ? commission_OperationTypeFromJSON(object.operationType) : undefined,
      operationMessage: isSet(object.operationMessage) ? String(object.operationMessage) : undefined,
      isNegative: isSet(object.isNegative) ? Boolean(object.isNegative) : undefined,
      pennyTolerance: isSet(object.pennyTolerance) ? Number(object.pennyTolerance) : undefined,
      pennyToleranceOwner: isSet(object.pennyToleranceOwner) ? String(object.pennyToleranceOwner) : undefined,
      monthCountChargeback: isSet(object.monthCountChargeback) ? Number(object.monthCountChargeback) : undefined,
    };
  },

  toJSON(message: Commission_IdentifiedResult): unknown {
    const obj: any = {};
    message.df1 !== undefined && (obj.df1 = message.df1 ? Commission_DfValue.toJSON(message.df1) : undefined);
    message.df2 !== undefined && (obj.df2 = message.df2 ? Commission_DfValue.toJSON(message.df2) : undefined);
    message.operationType !== undefined && (obj.operationType = message.operationType !== undefined
      ? commission_OperationTypeToJSON(message.operationType)
      : undefined);
    message.operationMessage !== undefined && (obj.operationMessage = message.operationMessage);
    message.isNegative !== undefined && (obj.isNegative = message.isNegative);
    message.pennyTolerance !== undefined && (obj.pennyTolerance = message.pennyTolerance);
    message.pennyToleranceOwner !== undefined && (obj.pennyToleranceOwner = message.pennyToleranceOwner);
    message.monthCountChargeback !== undefined && (obj.monthCountChargeback = Math.round(message.monthCountChargeback));
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_IdentifiedResult>, I>>(base?: I): Commission_IdentifiedResult {
    return Commission_IdentifiedResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_IdentifiedResult>, I>>(object: I): Commission_IdentifiedResult {
    const message = createBaseCommission_IdentifiedResult();
    message.df1 = (object.df1 !== undefined && object.df1 !== null)
      ? Commission_DfValue.fromPartial(object.df1)
      : undefined;
    message.df2 = (object.df2 !== undefined && object.df2 !== null)
      ? Commission_DfValue.fromPartial(object.df2)
      : undefined;
    message.operationType = object.operationType ?? undefined;
    message.operationMessage = object.operationMessage ?? undefined;
    message.isNegative = object.isNegative ?? undefined;
    message.pennyTolerance = object.pennyTolerance ?? undefined;
    message.pennyToleranceOwner = object.pennyToleranceOwner ?? undefined;
    message.monthCountChargeback = object.monthCountChargeback ?? undefined;
    return message;
  },
};

function createBaseCommission_ColumnDetail(): Commission_ColumnDetail {
  return {
    planType: undefined,
    rate: undefined,
    state: undefined,
    carrierId: undefined,
    comment: undefined,
    level: undefined,
    value: undefined,
  };
}

export const Commission_ColumnDetail = {
  encode(message: Commission_ColumnDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.planType !== undefined) {
      writer.uint32(8).int32(message.planType);
    }
    if (message.rate !== undefined) {
      writer.uint32(16).int32(message.rate);
    }
    if (message.state !== undefined) {
      writer.uint32(26).string(message.state);
    }
    if (message.carrierId !== undefined) {
      writer.uint32(34).string(message.carrierId);
    }
    if (message.comment !== undefined) {
      writer.uint32(42).string(message.comment);
    }
    if (message.level !== undefined) {
      writer.uint32(50).string(message.level);
    }
    if (message.value !== undefined) {
      writer.uint32(61).float(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_ColumnDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_ColumnDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.planType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rate = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.state = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.carrierId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.comment = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.level = reader.string();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_ColumnDetail {
    return {
      planType: isSet(object.planType) ? commission_PlanTypeFromJSON(object.planType) : undefined,
      rate: isSet(object.rate) ? commission_RateFromJSON(object.rate) : undefined,
      state: isSet(object.state) ? String(object.state) : undefined,
      carrierId: isSet(object.carrierId) ? String(object.carrierId) : undefined,
      comment: isSet(object.comment) ? String(object.comment) : undefined,
      level: isSet(object.level) ? String(object.level) : undefined,
      value: isSet(object.value) ? Number(object.value) : undefined,
    };
  },

  toJSON(message: Commission_ColumnDetail): unknown {
    const obj: any = {};
    message.planType !== undefined &&
      (obj.planType = message.planType !== undefined ? commission_PlanTypeToJSON(message.planType) : undefined);
    message.rate !== undefined &&
      (obj.rate = message.rate !== undefined ? commission_RateToJSON(message.rate) : undefined);
    message.state !== undefined && (obj.state = message.state);
    message.carrierId !== undefined && (obj.carrierId = message.carrierId);
    message.comment !== undefined && (obj.comment = message.comment);
    message.level !== undefined && (obj.level = message.level);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_ColumnDetail>, I>>(base?: I): Commission_ColumnDetail {
    return Commission_ColumnDetail.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_ColumnDetail>, I>>(object: I): Commission_ColumnDetail {
    const message = createBaseCommission_ColumnDetail();
    message.planType = object.planType ?? undefined;
    message.rate = object.rate ?? undefined;
    message.state = object.state ?? undefined;
    message.carrierId = object.carrierId ?? undefined;
    message.comment = object.comment ?? undefined;
    message.level = object.level ?? undefined;
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseCommission_CarrierPayoutRuleContainer(): Commission_CarrierPayoutRuleContainer {
  return { carrierPayoutRules: [] };
}

export const Commission_CarrierPayoutRuleContainer = {
  encode(message: Commission_CarrierPayoutRuleContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.carrierPayoutRules) {
      Commission_CarrierPayoutRule.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_CarrierPayoutRuleContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_CarrierPayoutRuleContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.carrierPayoutRules.push(Commission_CarrierPayoutRule.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_CarrierPayoutRuleContainer {
    return {
      carrierPayoutRules: Array.isArray(object?.carrierPayoutRules)
        ? object.carrierPayoutRules.map((e: any) => Commission_CarrierPayoutRule.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Commission_CarrierPayoutRuleContainer): unknown {
    const obj: any = {};
    if (message.carrierPayoutRules) {
      obj.carrierPayoutRules = message.carrierPayoutRules.map((e) =>
        e ? Commission_CarrierPayoutRule.toJSON(e) : undefined
      );
    } else {
      obj.carrierPayoutRules = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_CarrierPayoutRuleContainer>, I>>(
    base?: I,
  ): Commission_CarrierPayoutRuleContainer {
    return Commission_CarrierPayoutRuleContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_CarrierPayoutRuleContainer>, I>>(
    object: I,
  ): Commission_CarrierPayoutRuleContainer {
    const message = createBaseCommission_CarrierPayoutRuleContainer();
    message.carrierPayoutRules = object.carrierPayoutRules?.map((e) => Commission_CarrierPayoutRule.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseCommission_CarrierPayoutRule(): Commission_CarrierPayoutRule {
  return {
    type: undefined,
    versionName: undefined,
    versionID: undefined,
    ladderRule: undefined,
    payoutTable: undefined,
  };
}

export const Commission_CarrierPayoutRule = {
  encode(message: Commission_CarrierPayoutRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== undefined) {
      writer.uint32(8).int32(message.type);
    }
    if (message.versionName !== undefined) {
      writer.uint32(18).string(message.versionName);
    }
    if (message.versionID !== undefined) {
      writer.uint32(26).string(message.versionID);
    }
    if (message.ladderRule !== undefined) {
      ExecutiveLadderRule.encode(message.ladderRule, writer.uint32(34).fork()).ldelim();
    }
    if (message.payoutTable !== undefined) {
      Commission_PayoutTable.encode(message.payoutTable, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_CarrierPayoutRule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_CarrierPayoutRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.versionName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.versionID = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ladderRule = ExecutiveLadderRule.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.payoutTable = Commission_PayoutTable.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_CarrierPayoutRule {
    return {
      type: isSet(object.type) ? commissionTypeFromJSON(object.type) : undefined,
      versionName: isSet(object.versionName) ? String(object.versionName) : undefined,
      versionID: isSet(object.versionID) ? String(object.versionID) : undefined,
      ladderRule: isSet(object.ladderRule) ? ExecutiveLadderRule.fromJSON(object.ladderRule) : undefined,
      payoutTable: isSet(object.payoutTable) ? Commission_PayoutTable.fromJSON(object.payoutTable) : undefined,
    };
  },

  toJSON(message: Commission_CarrierPayoutRule): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? commissionTypeToJSON(message.type) : undefined);
    message.versionName !== undefined && (obj.versionName = message.versionName);
    message.versionID !== undefined && (obj.versionID = message.versionID);
    message.ladderRule !== undefined &&
      (obj.ladderRule = message.ladderRule ? ExecutiveLadderRule.toJSON(message.ladderRule) : undefined);
    message.payoutTable !== undefined &&
      (obj.payoutTable = message.payoutTable ? Commission_PayoutTable.toJSON(message.payoutTable) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_CarrierPayoutRule>, I>>(base?: I): Commission_CarrierPayoutRule {
    return Commission_CarrierPayoutRule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_CarrierPayoutRule>, I>>(object: I): Commission_CarrierPayoutRule {
    const message = createBaseCommission_CarrierPayoutRule();
    message.type = object.type ?? undefined;
    message.versionName = object.versionName ?? undefined;
    message.versionID = object.versionID ?? undefined;
    message.ladderRule = (object.ladderRule !== undefined && object.ladderRule !== null)
      ? ExecutiveLadderRule.fromPartial(object.ladderRule)
      : undefined;
    message.payoutTable = (object.payoutTable !== undefined && object.payoutTable !== null)
      ? Commission_PayoutTable.fromPartial(object.payoutTable)
      : undefined;
    return message;
  },
};

function createBaseCommission_PayoutTable(): Commission_PayoutTable {
  return { columnNames: [], lockedLevelFromCarrier: [], table: [] };
}

export const Commission_PayoutTable = {
  encode(message: Commission_PayoutTable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.columnNames) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.lockedLevelFromCarrier) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.table) {
      Commission_ColumnDetail.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_PayoutTable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_PayoutTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.columnNames.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lockedLevelFromCarrier.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.table.push(Commission_ColumnDetail.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_PayoutTable {
    return {
      columnNames: Array.isArray(object?.columnNames) ? object.columnNames.map((e: any) => String(e)) : [],
      lockedLevelFromCarrier: Array.isArray(object?.lockedLevelFromCarrier)
        ? object.lockedLevelFromCarrier.map((e: any) => String(e))
        : [],
      table: Array.isArray(object?.table) ? object.table.map((e: any) => Commission_ColumnDetail.fromJSON(e)) : [],
    };
  },

  toJSON(message: Commission_PayoutTable): unknown {
    const obj: any = {};
    if (message.columnNames) {
      obj.columnNames = message.columnNames.map((e) => e);
    } else {
      obj.columnNames = [];
    }
    if (message.lockedLevelFromCarrier) {
      obj.lockedLevelFromCarrier = message.lockedLevelFromCarrier.map((e) => e);
    } else {
      obj.lockedLevelFromCarrier = [];
    }
    if (message.table) {
      obj.table = message.table.map((e) => e ? Commission_ColumnDetail.toJSON(e) : undefined);
    } else {
      obj.table = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_PayoutTable>, I>>(base?: I): Commission_PayoutTable {
    return Commission_PayoutTable.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_PayoutTable>, I>>(object: I): Commission_PayoutTable {
    const message = createBaseCommission_PayoutTable();
    message.columnNames = object.columnNames?.map((e) => e) || [];
    message.lockedLevelFromCarrier = object.lockedLevelFromCarrier?.map((e) => e) || [];
    message.table = object.table?.map((e) => Commission_ColumnDetail.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCommission_DfValue(): Commission_DfValue {
  return { dfInfo: undefined, value: undefined, row: undefined, column: undefined, columnDetail: undefined };
}

export const Commission_DfValue = {
  encode(message: Commission_DfValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dfInfo !== undefined) {
      Commission_CarrierPayoutRule.encode(message.dfInfo, writer.uint32(10).fork()).ldelim();
    }
    if (message.value !== undefined) {
      writer.uint32(17).double(message.value);
    }
    if (message.row !== undefined) {
      writer.uint32(26).string(message.row);
    }
    if (message.column !== undefined) {
      writer.uint32(34).string(message.column);
    }
    if (message.columnDetail !== undefined) {
      Commission_ColumnDetail.encode(message.columnDetail, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_DfValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_DfValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dfInfo = Commission_CarrierPayoutRule.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.value = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.row = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.column = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.columnDetail = Commission_ColumnDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_DfValue {
    return {
      dfInfo: isSet(object.dfInfo) ? Commission_CarrierPayoutRule.fromJSON(object.dfInfo) : undefined,
      value: isSet(object.value) ? Number(object.value) : undefined,
      row: isSet(object.row) ? String(object.row) : undefined,
      column: isSet(object.column) ? String(object.column) : undefined,
      columnDetail: isSet(object.columnDetail) ? Commission_ColumnDetail.fromJSON(object.columnDetail) : undefined,
    };
  },

  toJSON(message: Commission_DfValue): unknown {
    const obj: any = {};
    message.dfInfo !== undefined &&
      (obj.dfInfo = message.dfInfo ? Commission_CarrierPayoutRule.toJSON(message.dfInfo) : undefined);
    message.value !== undefined && (obj.value = message.value);
    message.row !== undefined && (obj.row = message.row);
    message.column !== undefined && (obj.column = message.column);
    message.columnDetail !== undefined &&
      (obj.columnDetail = message.columnDetail ? Commission_ColumnDetail.toJSON(message.columnDetail) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_DfValue>, I>>(base?: I): Commission_DfValue {
    return Commission_DfValue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_DfValue>, I>>(object: I): Commission_DfValue {
    const message = createBaseCommission_DfValue();
    message.dfInfo = (object.dfInfo !== undefined && object.dfInfo !== null)
      ? Commission_CarrierPayoutRule.fromPartial(object.dfInfo)
      : undefined;
    message.value = object.value ?? undefined;
    message.row = object.row ?? undefined;
    message.column = object.column ?? undefined;
    message.columnDetail = (object.columnDetail !== undefined && object.columnDetail !== null)
      ? Commission_ColumnDetail.fromPartial(object.columnDetail)
      : undefined;
    return message;
  },
};

function createBaseCommission_Preprocessing(): Commission_Preprocessing {
  return {
    carrierId: [],
    planTypeCommission: [],
    state: [],
    rate: [],
    level: [],
    year: [],
    enrollmentPolicyStatus: [],
  };
}

export const Commission_Preprocessing = {
  encode(message: Commission_Preprocessing, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.carrierId) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.planTypeCommission) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.state) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.rate) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.level) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.year) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.enrollmentPolicyStatus) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission_Preprocessing {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission_Preprocessing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.carrierId.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.planTypeCommission.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.state.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rate.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.level.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.year.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.enrollmentPolicyStatus.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commission_Preprocessing {
    return {
      carrierId: Array.isArray(object?.carrierId) ? object.carrierId.map((e: any) => String(e)) : [],
      planTypeCommission: Array.isArray(object?.planTypeCommission)
        ? object.planTypeCommission.map((e: any) => String(e))
        : [],
      state: Array.isArray(object?.state) ? object.state.map((e: any) => String(e)) : [],
      rate: Array.isArray(object?.rate) ? object.rate.map((e: any) => String(e)) : [],
      level: Array.isArray(object?.level) ? object.level.map((e: any) => String(e)) : [],
      year: Array.isArray(object?.year) ? object.year.map((e: any) => String(e)) : [],
      enrollmentPolicyStatus: Array.isArray(object?.enrollmentPolicyStatus)
        ? object.enrollmentPolicyStatus.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: Commission_Preprocessing): unknown {
    const obj: any = {};
    if (message.carrierId) {
      obj.carrierId = message.carrierId.map((e) => e);
    } else {
      obj.carrierId = [];
    }
    if (message.planTypeCommission) {
      obj.planTypeCommission = message.planTypeCommission.map((e) => e);
    } else {
      obj.planTypeCommission = [];
    }
    if (message.state) {
      obj.state = message.state.map((e) => e);
    } else {
      obj.state = [];
    }
    if (message.rate) {
      obj.rate = message.rate.map((e) => e);
    } else {
      obj.rate = [];
    }
    if (message.level) {
      obj.level = message.level.map((e) => e);
    } else {
      obj.level = [];
    }
    if (message.year) {
      obj.year = message.year.map((e) => e);
    } else {
      obj.year = [];
    }
    if (message.enrollmentPolicyStatus) {
      obj.enrollmentPolicyStatus = message.enrollmentPolicyStatus.map((e) => e);
    } else {
      obj.enrollmentPolicyStatus = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission_Preprocessing>, I>>(base?: I): Commission_Preprocessing {
    return Commission_Preprocessing.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission_Preprocessing>, I>>(object: I): Commission_Preprocessing {
    const message = createBaseCommission_Preprocessing();
    message.carrierId = object.carrierId?.map((e) => e) || [];
    message.planTypeCommission = object.planTypeCommission?.map((e) => e) || [];
    message.state = object.state?.map((e) => e) || [];
    message.rate = object.rate?.map((e) => e) || [];
    message.level = object.level?.map((e) => e) || [];
    message.year = object.year?.map((e) => e) || [];
    message.enrollmentPolicyStatus = object.enrollmentPolicyStatus?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
